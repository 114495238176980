.arrowicon_change {
  .ant-table-tbody tr td:nth-child(1) {
    font-weight: 400;
  }

  .ant-table-row-expand-icon {
    font-size: 20px;
    background-color: transparent;
    border: none;
    font-weight: 400;
  }

  .ant-table-row-expand-icon::after {
    position: absolute;
    background: none !important;
    transition: transform 0.1s ease-out;
    content: ">";
    display: inline-block;
    position: absolute;
    top: -22%;
    left: 50%;
    margin-left: -0.7em;
    width: 1.4em;
    height: 1.4em;
    line-height: 1.3em;
  }

  .ant-table-row-expand-icon-collapsed::before {
    display: none;
  }

  .ant-table-row-expand-icon::before {
    display: none;
  }
}

.pricing_table {
  .ant-form-item {
    margin: 0;
  }
}

.label-align {

  label {
    width: 105px;
    display: flex;
    justify-content: end;
  }
}

.add-discount-form_modal {
  p:nth-child(1) {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #1A7F93;
    font-family: "Poppins";
  }

  p:nth-child(2) {
    margin: 0;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #000000D9;
    font-family: "Poppins";
    margin-bottom: 1rem;
  }
}

.current-travelers {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid #F0F0F0;

  .current_title {
    p {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 22px;
      color: #000000D9;
      font-weight: 400;
    }
  }


  .currnet_traveler_name {
    p {
      font-family: "Poppins";
      font-size: 14px;
      line-height: 22px;
      color: #000000D9;
      font-weight: 600;

      span {
        font-family: "Poppins";
        font-size: 12px;
        line-height: 20px;
        color: #000000D9;
        opacity: 0.45;
        font-weight: 400;
      }
    }
  }
}

.pb-2 {
  padding-bottom: 8px;
}

.mb-3 {
  margin-bottom: 1rem;
}

.form-title {
  background-color: #FAFAFA;
  padding: 4px 1rem;

  p {
    font-size: 14px !important;
    line-height: 22px !important;
    font-family: "Poppins" !important;
    font-weight: 700 !important;
    color: #000000 !important;
    margin: 0 !important;
  }
}

.mb-2 {
  margin-bottom: 8px;
}

.cross-icon {
  width: 1rem !important;
  height: 1rem !important;
}

.cross-icon svg {
  width: 1rem !important;
  height: 1rem !important;
}

.cancel_table {
  padding-left: 2rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.text-bold {
  font-weight: 700 !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #000000D9 !important;
}

.mb-20 {
  margin-bottom: 3rem;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;