.white_space {

  .ant-card {
    width: 50%;
  }

  @media screen and (min-width: 0px) and (max-width: 576px) {
    white-space: nowrap;
    width: 100%;
    display: flex;
    overflow: auto;
    gap: 10px;
  }
}

.card_anchor {
  display: flex;
  justify-content: space-around;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 14px;

  button {
    border: unset;
    border-color: unset;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;