.addnote_breadcrumb {
  background-color: #fff;
  margin-top: 0 !important;
  margin-bottom: 24px !important;

  .addnote_content {
    display: flex;
    gap: 19px;

    img {
      width: 14px;
      height: 14px;
      margin-top: 6px;
    }

    .breadcrumb_title {
      display: flex;
      gap: 12px;
      @media screen and (min-width: 0px) and (max-width: 576px) {
        flex-direction: column;
        gap: 0;
      }

      p:nth-child(1) {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0;
      }

      p:nth-child(2) {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 3px;
        margin-bottom: 0;
      }
    }
  }
}

@primary-color: #1A7F93;@processing-color: #1A7F93;