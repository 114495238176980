// .ant-layout {
//     .ant-layout-header {
//         background: #F0F2F5;
//         box-shadow: inset 0px -1px 0px #F0F0F0;
//         height: 48px;
//         .horizontal_menu {
//             ul {
//                 background: #F0F2F5;
//                 box-shadow: inset 0px -1px 0px #F0F0F0;
//             }
//         }
//     }
//     .ant-layout-has-sider{
//         .header_content{
//             position: absolute;
//             top: -4rem;
//         }
//     }
// }
.record_breadcrumb{
    margin-top: 0 !important;
  ol{
    li:nth-child(2){
        .ant-breadcrumb-separator{
            display: none;
        }
    }
  }
}





@primary-color: #1A7F93;@processing-color: #1A7F93;