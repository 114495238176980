.Res_collapse_maindiv{
  .ant-collapse{
    border: none;
   
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    align-items: center;
    background-color: #fafafa;
    overflow-x: scroll
  }
}

.res_form_main {
  .res_form_text {
    background-color: #fafafa;
    padding: 4px 16px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Poppins";
    margin-bottom: 20px;
    h4 {
      margin: 0px;
      font-weight: 700;
    }
  }
}
.collapse_header_main{
  display: flex;
  align-items: baseline;
  .space_div{
    margin-right: 20px;
  }
}

// .text_break_second{
//   display: block !important;
//   line-height: 16px !important;
//   height: 80px !important;
// }
@primary-color: #1A7F93;@processing-color: #1A7F93;