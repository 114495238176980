.site-layout-background {
  h4 {
    margin-left: 7px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 0;
  }

  .ant-table-wrapper {
    .ant-table-small {
      .ant-table-container {
        table {
          .ant-table-tbody {
            tr {
              td:first-child {
                // display: inline-block;
                // width: 295px !important;
                width: 500px !important;
                height: 46px !important;
                // padding-top: 11px !important;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
              }

              // td:last-child {
              //   // display: inline-block;
              //   width: 480px !important;
              //   height: 46px !important;
              //   // padding-top: 11px !important;
              //   font-family: "Poppins";
              //   font-style: normal;
              //   font-weight: 400;
              //   font-size: 14px;
              //   color: rgba(0, 0, 0, 0.85);
              // }

              &:hover {
                background-color: unset;
              }
            }

            .ant-table-row-level-0:nth-child(13) {
              td:last-child {
                // color: #0099e5;
              }
            }

            .ant-table-row-level-0:nth-child(11) {
              td:last-child {
                // color: #0099e5;
              }
            }
          }
        }
      }
    }
  }
}

.details-tabs {
  .requests {
    .ant-table-thead {
      tr:first-child th:last-child {
        width: 115px;
      }
    }
  }
}

.events-header .breadcrumb_content .view_header {
  padding-top: 0;
  padding-bottom: 0 I !important;
}

.category_itinerary_table .ant-table-tbody>tr>td {
  white-space: break-spaces !important;
  vertical-align: top !important;
  text-align: start !important;
  max-width: 270px;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;