.document_card {

  .ant-card-bordered {
    border: none;

    .ant-card-body {
      padding: 0;

      &:hover {
        .card_buttons {
          opacity: 1;
          visibility: visible;
        }
      }

      .card_meta {
        display: flex;
        justify-content: space-between;


        .ant-card-meta-avatar {
          margin-top: 10px;
        }

        .card_buttons {
          display: flex;
          gap: 12px;
          justify-content: end;
          flex-wrap: wrap;


        }
      }
    }
  }

}

.add_new {

  .ant-dropdown-menu {
    top: 0;
  }
}

.document_pdf {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  font-family: Poppins;
  color: rgba(0, 0, 0, 0.85);
  margin: 0;
  padding-bottom: 7px;
}

.buttons_flex {
  display: flex;
  gap: 12px;
}


.add_dining {

  .ant-dropdown-menu {
    top: -14px;
  }
}

.arrow_class {
  font-size: 15px;
  margin-right: 12px;
}

.assets_dropdown {
  .ant-dropdown-menu {
    top: -5px;
  }
}

.addnotes_collapse {
  .ant-collapse-expand-icon {
    top: 0;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;