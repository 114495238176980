.search_filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.carddrawer {

    &_heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            margin: 0;
            position: relative;

            &::before {
                content: '.';
                position: absolute;
                background-color: #52C41A;
                width: 6px;
                height: 6px;
                top: 40%;
                left: -12px;
                border-radius: 50%;

            }

        }
    }

    &_paragraph {
        p {
            margin: 0;
            margin-top: 12px;
        }
    }

    &_content {
        display: flex;
        gap: 100px;
        margin-top: 12px;
        margin-bottom: 12px;

        &--price {
            p {
                margin: 0;
            }
        }

        &--quantity {
            p {
                margin: 0;
            }
        }
    }

    button {
        width: 100%;
    }
}

.dashboard-header h3 {
    padding-bottom: 10px;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;