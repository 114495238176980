.main_login-container--logo {
  position: relative;

  .forgot_arrow {
    position: absolute;
    left: o;
    font-size: 20px;

    .anticon svg {
      color: #000;
    }
  }
}

.forgot_emailaddress {
  position: relative;

  p {
    position: absolute;
    right: 0;
    z-index: 999;

    span {
      color: rgba(0, 0, 0, 0.45);
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }

  a {
    position: absolute;
    right: 0;
    color: rgba(0, 0, 0, 0.45);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    z-index: 999;
  }

  .ant-form-item-row {
    align-items: unset !important;
  }
}

.forgot_main {
  h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  p {
    margin: 0;
  }

}

.select_search_tooltip {
  svg {
    position: absolute;
    right: -25px;
    top: 14px;
  }
}

.ant-upload-list-item-card-actions:hover .anticon-delete {
  color: red !important;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;