.membership_programs {
    margin: -24px !important;

    .membership_buttons {
        display: flex;
        gap: 10px;
        margin-bottom: 12px;
    }

    .card_anchor {
        display: flex;
        justify-content: space-around;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        margin-top: 10px;

        a {
            color: #0099E5;
        }
    }
}

.membership_buttons {
    display: flex;
    gap: 5px;
    margin-bottom: 12px;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;