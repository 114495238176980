// .text_break_third{
//     height: 100px;

// }
// .text_break_four{
//     height: 100px;

// }

// .label_spans{
//     white-space: pre-wrap;
// }


// .label_spans {
//     &::after {
//         display: none;
//     }
// }

.ant-form-item-row{
    align-items: center;

    .ant-drawer-body & {
        align-items: unset;
    }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;