.close_relative {
  position: relative;

  .close_absolute {
    position: absolute;
    right: -60%;
  }
}

@media screen and (max-width: 767px) {
  .close_absolute {
    right: 0 !important;
  }
}


.group_details_cross {
  .ant-select-clear {
    right: 1rem !important;
  }
}

.complete_events_filter {
  .ant-select-clear {
    right: 1rem !important;
  }
}

.ant-select-selection-overflow-item {
  font-family: "Poppins";
}

.ant-select-selection-overflow {
  font-family: "Poppins";
}
@primary-color: #1A7F93;@processing-color: #1A7F93;