.ant-card-type-inner {
  @media screen and (min-width: 0px) and (max-width: 576px) {
      width: unset !important;
  }
}

.ant-card-body {
    padding: 16px 24px 0 24px !important;
}

.card_button {
    margin-bottom: 3.5rem;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;