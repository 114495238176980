.ant-breadcrumb {
  @media screen and (min-width: 992px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  li:nth-last-child(1) {
    span.ant-breadcrumb-link {
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 400 !important;
    }

    .ant-breadcrumb-separator {
      display: none;
    }
  }
}

.breadcrumb_content {
  width: 100%;

  .breadcrumb_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // flex-wrap: wrap;
    h3 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      // margin-top: 16px;
      margin: 0;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;

      .small_text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        padding-left: 7px;
      }
    }

    .breadcrum_buttons {
      display: flex;
      gap: 10px;
      justify-content: end;
      flex-wrap: nowrap;
    }
  }
}

.back-icon-profile {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.85);
}

.small_text_two {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.number-arrows {

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.header_smalltext {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}


.ant-upload-list-item-card-actions {
  color: red !important;

  &:hover {
    color: red !important;
  }

  .ant-upload-list-item-card-actions-btn {
    &:hover {
      color: red !important;
    }

    span {
      &:hover {
        color: red !important;
      }
    }
  }
}


.card_anchor {
  padding-top: 12px;

  .ant-btn:nth-child(2) {
    &:hover:enabled {
      color: red;
    }
  }
}

.ant-modal-wrap {
  &::-webkit-scrollbar {
    display: none;
  }
}


.empty_data {
  display: flex;
  justify-content: center;
  margin: auto;
}

.button_style {
  margin-top: 1rem;
}

.pt-3 {
  padding-top: 1rem;
}

.breadcrumb_content .pb-0 {
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pe-2 {
  padding-right: 8px;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;