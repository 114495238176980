.Res_guest_main{
    .basic_details .ant-table-wrapper .ant-spin-nested-loading .ant-table-tbody tr td:first-child {
        width: 200px !important;
        height: 46px !important;
        padding-top: 11px !important;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
    }
    .basic_details .ant-table-wrapper .ant-spin-nested-loading .ant-table-tbody tr td:last-child  {
        padding-top: 11px !important;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    }

}



@primary-color: #1A7F93;@processing-color: #1A7F93;