.admin_table {

    table {
        tbody {
            tr {
                td:first-child {
                    width: 500px;
                }
            }
        }
    }
}

.manage_padding {
    padding-left: 24px;
}

.stafhold_table {

    table {
        tbody {
            tr {
                td:first-child {
                    width: 138px;

                    @media screen and (max-width: 576px) {
                        width: 164px;
                    }
                }
            }
        }
    }


}

.asterick-align {
    .ant-form-item-row {
      align-items: unset;
    }
  
    .ant-form-item-label {
      margin-top: 5px;
    }
  
    // .ant-form-item-has-error {
    //   margin-bottom: 60px;
    // }
  
    // .ant-form-item-control-input + div {
    //   position: absolute;
    //   z-index: 100;
    // }
  }

.payment_table {

    table {
        tbody {
            tr {
                td:nth-child(1) {
                    width: 200px;
                }

                td:nth-child(2) {
                    width: 180px !important;

                    p{
                        color: unset !important;
                    }
                }

                td:nth-child(3) {
                    width: 250px !important;
                }

                td:nth-child(4) {
                    width: 150px !important;
                }

                td:nth-child(5) {
                    width: 150px !important;
                }

                td:nth-child(6) {
                    width: 180px !important;
                }

                &::hover{
                    color: unset !important;
                }
            }

            tr:nth-child(1) {
                background-color: #F5F5F5;

                &:hover,
                &:focus,
                &:active {
                    background-color: #F5F5F5 !important;
                }
            }
        }
    }
}

.pay_table {

    table {
        tbody {
            tr {
                td:nth-child(1) {
                    width: 200px;
                }

                td:nth-child(2) {
                    width: 180px !important;
                }

                td:nth-child(3) {
                    width: 250px !important;
                }

                td:nth-child(4) {
                    width: 150px !important;
                }

                td:nth-child(5) {
                    width: 150px !important;
                }

                td:nth-child(6) {
                    width: 180px !important;
                }
            }

            tr:nth-child(1) {
                background-color: unset;

                &:hover,
                &:focus,
                &:active {
                    background-color: #F5F5F5 !important;
                }
            }
        }
    }
}


.medi_table {

    table {
        tbody {
            tr {
                td:first-child {
                    width: 295px;
                }
            }
        }
    }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;