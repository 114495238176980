
.steps-action{
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    padding-top: 24px;
    margin-left: -14rem;
    .mobileview & {
        margin-left: 0;
      }
}
.ant-input-group.ant-input-group-compact{
    display: flex;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
    .ant-layout-sider{
        display: none;
    }
    .border_container{
        border-left: none;
     }
 
    
}

@primary-color: #1A7F93;@processing-color: #1A7F93;