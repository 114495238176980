.primary-color {
  background: #002233;
  color: #fff;
}
.secondary-color {
  background-color: #0099e5;
  color: #fff;
}
.tertiary-color {
  background: #004466;
  color: #fff;
}
.background-shade {
  background: #f0f2f5;
}
.font-shade {
  background-color: rgba(0, 0, 0, 0.85);
  color: #fff;
}

.theme-color {
   
  .ant-card-body {
    position: relative;
    text-align: right;

    p {
      margin-bottom: -18px;
      padding-top: 30px;
    }
  }
}

.theme-color-wrap {
    position: relative;
    text-align: left;
}

.styleguide-section {
 
        h3.cardheading {
            text-align: left;
    font-size: 50px;
    font-weight: bold;
    opacity: .2;
    margin-top: -13px;
        }
    }

.demo-step {
  .steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 80px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 24px;
  }
}

// .styleguide-section {
//   input,
//   .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//     height: 40px !important;
//   }
// }

@primary-color: #1A7F93;@processing-color: #1A7F93;