.reservat_table {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  tr {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    td {
      p {
        margin: 0;
      }
    }

    td:nth-child(1) {
      color: #0099E5;
    }

    td:nth-child(3) {
      span {
        font-size: 3rem;
        margin-top: -14px;
      }
    }

  }

  tr:nth-child(1) {
    td:nth-child(3) {
      span {
        color: #52C41A;
      }
    }
  }

  tr:nth-child(2) {
    td:nth-child(3) {
      span {
        color: #52C41A;
      }
    }
  }


  tr:nth-child(3) {
    td:nth-child(3) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(4) {
    td:nth-child(3) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(5) {
    td:nth-child(3) {
      span {
        color: #FF4D4F;
      }
    }
  }

  tr:nth-child(10) {
    td:nth-child(3) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(8) {
    td:nth-child(3) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(4) {
    td:nth-child(3) {
      span {
        color: #0099E5;
      }
    }
  }



}

.add_ne {

  .ant-dropdown-menu {
    top: 10px;
  }
}

.add_cat {

  .ant-dropdown-menu {
    top: -10px;
  }
}


.add_width {

  .ant-dropdown-menu {
    top: 0px;
    width: 260px;
  }
}

.email_width {

  .ant-dropdown-menu {
    top: 0px;
    width: 240px;
  }
}

.res_payment {

  .ant-dropdown-menu {
    top: 10px;
    width: 200px;
  }
}


.profile-dropdown {
  .ant-dropdown-menu {
    top: -1rem;
  }
}

.ant-dropdown-open {
  .active-filter {
    background-color: rgba(26, 127, 147, 0.2) !important;

    svg {
      color: #1a7f93 !important;
    }
  }
}

.active-hover:hover {
  background-color: rgba(26, 127, 147, 0.2) !important;

  svg {
    color: #1a7f93 !important;
  }
}

.inactive:hover {
  background-color: rgba(26, 127, 147, 0.2) !important;

  svg {
    color: #1a7f93 !important;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;