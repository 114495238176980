.main_dashboard {
    padding: 0px 20px;

}

.dashboard-header {
    h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 0px;
    }

    .ant-card-body {
        padding: 16px;
        border-radius: 2px;

        @media screen and (min-width: 768px) {
            padding: 16px 24px;
        }
    }

}

.dash-section-one {
    h2 {
        border-bottom: 1px solid #f0f2f6;
        padding: 10px 0px;
        font-weight: 500;
        font-size: 30px;
        line-height: 40px;

    }

    h5 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);

        span {
            color: #000;
            padding: 0px 3px;
            font-size: 1rem;

        }
    }

    .ant-card-body {
        border-radius: 2px;
        padding: 20px 10px 12px 10px;

        @media screen and (min-width: 768px) {
            padding: 20px 24px 12px 24px;
        }
    }

}

.dash-section-two {
    .ant-card-body {
        padding: 16px 24px;
        border-radius: 2px;
    }

    h3 {
        font-weight: 500;
        // font-size: 16px;
        // line-height: 24px;
        // margin-bottom: 12px;
    }
}

.dash-section-three {
    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 12px;
    }

    .ant-card-body {
        padding: 16px 24px;
        border-radius: 2px;

    }
}

@media screen and (max-width: 991px) {
    .dash-section-one {
        margin-left: 4px !important;
        margin-right: 4px !important;
        margin-bottom: 0px !important;

        .ant-card {
            margin-bottom: 20px;
        }
    }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;