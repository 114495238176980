.details_icon {
  margin-right: 18px;
}

.ant-collapse {
  background-color: #fff;
}

.general-reservation_row {
  height: 100%;
  margin-bottom: 1.5rem;

  .h-100 {
    height: 100%;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.flights-accordion {

  .ant-collapse-header {
    display: flex;
    align-items: center !important;
  }

  .ant-collapse-expand-icon {
    top: 0;
  }

  .ant-collapse-header-text {
    font-weight: bold;
  }

  .tag_btn {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.layout-content:has(.assign-table) {
  transform: unset !important;
  max-width: 100% !important;
}

.ant-select-item-option-content {
  text-transform: capitalize;
}

.requests_title {
  .ant-select-show-search {
    background-color: #000 !important;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;