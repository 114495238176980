.main-sidebar {
  background-color: #002233;
  width: 214px !important;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  // margin-right: auto;
  z-index: 1000;

  >div {
    // position: fixed;
    // left: 0;
    // top: 0;
    // height: 100vh;
    width: 100%;
  }

  .mobileview & {
    display: none;
  }
}

.aside-menu {
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #004466;
  }

  //  
  ul {
    background-color: #002233;
    border-right: 0;

    a,
    span,
    i {
      color: #fff;
    }
  }

  .ant-menu-sub {
    background-color: #002233;
  }

  .ant-drawer-header {
    border-bottom: 0;
  }
}

.modal_group {
  display: none !important;
}

.searchox_border {
  border: 2px solid #d9d9d9;
  // max-width: 500px;
  max-width: 100% !important;


}

.modal_advanced-searchbox {
  top: 0;
  // right: 12px !important;
  padding-right: 12px !important;
}

.modalcustom_search {
  position: absolute;
  width: 99%;
  margin-left: 4px;

  @media screen and (max-width: 991px) {
    position: absolute;
    right: 31px;
    left: 30px;
  }
}

.guest_dropdown {

  .ant-dropdown-menu {
    top: 5px;
    max-width: 144px;
  }
}

.guest_drop {

  .ant-dropdown-menu {
    top: -5px;
    max-width: 142px;
  }
}


.member_hover {

  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 15px;
  padding-left: 14px;
  color: gray;

  &:hover {

    background-color: #f0f0f0;
    color: #1A7F93;
    cursor: pointer;

  }
}

.view-event--cta {
  transition: unset !important;

  .member_hover {
    transition: unset !important;

    &:hover {

      background-color: unset !important;
      color: unset !important;
    }
  }

  &:hover {
    border: 1px solid #1A7F93 !important;
    outline: unset !important;
    box-shadow: unset !important;
  }
}

.hover_cursor {
  cursor: pointer;
}

.menu_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    .headersidebar_button {
      display: block;
    }
  }
}

.headersidebar_button {
  background-color: transparent;
  border-color: #1A7F93;
  display: none;
  color: #fff;

  &:hover,
  &:focus {
    background-color: #1A7F93;
    border-color: #1A7F93;
    color: #fff;
  }
}

.headersidebar_hover {
  display: none;
}

.menus_background {

  &:hover,
  &:focus {
    background-color: #084853;
    box-shadow: inset -3px 0px 0px #46B2C8;
  }
}

.tooltip_bold {
  font-weight: 700;
}

.color_link {
  color: #fff !important;

  &:hover {
    color: #fff !important;
  }
}


.dublicate_search {
  position: absolute;
}

.duplicate-search-group {
  max-width: 100% !important;
}

.searchbox+.empty-col {
  display: none;
}

.assign-permission-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}
@primary-color: #1A7F93;@processing-color: #1A7F93;