.addnotes_collapse {
  border: none;

  .ant-collapse-content-active {
    border: none;
  }

  .ant-collapse-content-hidden {
    border: none;
  }

  .ant-collapse-content,
  .ant-collapse-content-inactive,
  .ant-collapse-content-hidden {
    border: none !important;
  }

  .ant-collapse-header {
    color: #0099e5 !important;
    text-align: left;
    margin-bottom: 1rem;
    background-color: #fff;
    padding-left: 8rem !important;
    padding-right: 8rem !important;
    text-align: left;

    @media screen and (max-width: 1200px) and (min-width: 767px) {
      padding-left: 11rem !important;
    }

    @media screen and (max-width: 767px) and (min-width: 0px) {
      padding-left: 0 !important;
    }
  }

  .end_time {
    display: flex;
  }
}

.ant-collapse-item {
  border-bottom: none !important;
}

.form_align {

  .form_label {
    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.5rem;
    }
  }

  .ant-picker {
    width: 49%;
  }
}

@media (min-width: 1600px) {

  .form_align {
    position: relative;

    .form_label {
      .ant-picker {
        display: flex;
        justify-content: end;
        margin-left: -5.5rem;
        width: 45%;
      }
    }
  }
}

.form_col_align {
  position: relative;

  .form_label {
    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.5rem;
      margin-left: 7.7rem;
      width: 100%;
    }
  }

  .ant-picker {
    width: 49%;
  }
}

@media (min-width: 1600px) {

  .form_col_align {
    position: relative;

    .form_label {
      .ant-picker {
        margin-left: 2rem;
      }
    }
  }
}

.background_div {
  height: 24px;
  margin: 0 -50px;
  background: #f0f2f5;
}


.form_modal_align {
  position: relative;

  .form_label {
    position: absolute;
    top: 0;
    left: 59%;
    width: 100%;
  }

  .ant-form-item-control-input {
    display: inline-block;
    width: 50%;
  }
}

.form_align_select {

  .ant-select {
    width: 48% !important;
    position: absolute;
    top: 0;
    z-index: 999;
  }

  .ant-select-selector {
    width: 100% !important;
  }

  .form_label {
    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.5rem;
    }
  }

  input {
    width: 49%;
    margin-left: 6.5rem;
  }
}

@media (min-width: 1600px) {

  .form_align_select {
    position: relative;

    .form_label {
      input {
        display: flex;
        justify-content: end;
        margin-left: -5.5rem;
        width: 45%;
      }
    }
  }
}



.form_align_select_two {

  .ant-select {
    width: 48% !important;
    position: absolute;
    top: 0;
    z-index: 999;
  }

  .ant-select-selector {
    width: 100% !important;
  }

  .form_label {
    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.5rem;
    }

    .ant-form-item-control-input {
      width: 48%;
      margin-left: 7rem;
    }
  }
}

@media (min-width: 1600px) {

  .form_align_select_two {
    position: relative;

    .form_label {
      .ant-form-item-control-input {
        width: 48%;
        margin-left: -7rem;
      }
    }
  }
}



.time-picker_align {
  position: relative;

  .form_label {
    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.5rem;
      margin-left: 14.8rem;
      width: 100%;
    }
  }

  .ant-picker {
    width: 45%;
  }

}

@media (min-width: 1500px) {

  .time-picker_align {
    position: relative;

    .form_label {
      .ant-form-item-row {
        display: flex;
        justify-content: end;
        margin-top: -3.5rem;
        margin-left: 7rem;
        width: 100%;
      }
    }

    .form_label {
      .ant-picker {
        margin-left: 2rem;
      }
    }

    .ant-picker {
      width: 47%;
    }
  }
}

@media (min-width: 1600px) {

  .time-picker_align {
    position: relative;

    .form_label {
      .ant-form-item-row {
        display: flex;
        justify-content: end;
        margin-top: -3.5rem;
        margin-left: 8rem;
        width: 100%;
      }
    }

    .form_label {
      .ant-picker {
        margin-left: 2rem;
      }
    }

    .ant-picker {
      width: 47%;
    }
  }
}

.label-col {

  .ant-form-item-control-input-content {
    padding-left: 1rem;
  }

  .ant-form-item-row {
    display: flex;
    justify-content: end;
    margin-top: -3.5rem;
  }
}

.label-col_single {

  .ant-form-item-control-input-content {
    padding-left: 1rem;
  }

  .ant-form-item-row {
    display: flex;
    justify-content: end;
    margin-top: -3.5rem;
  }
}

@media (min-width: 1400px) and (max-width: 1499.98px) {
  .label-col {

    .ant-form-item-control-input-content {
      padding-left: 1rem;
    }

    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.5rem;
    }
  }

  .label-col_single {

    .ant-form-item-control-input-content {
      padding-left: 1rem;
    }

    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.5rem !important;
    }
  }
}

@media (max-width: 1400.98px) {
  .label-col--sm {
    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.85rem;
    }
  }
}

@media (max-width: 1224.98px) {
  .label-col--smx {
    .ant-form-item-row {
      display: flex;
      justify-content: end;
      margin-top: -3.85rem;
    }
  }
}

.select-conatct_modal {
  .ant-modal-body {
    max-height: 450px;
    overflow-y: scroll;
  }
}

.select-conatct_modal .ant-modal-body::-webkit-scrollbar {
  width: 10px;
}

.select-conatct_modal .ant-modal-body::-webkit-scrollbar-track {
  background-color: #bbb;
}

.select-conatct_modal .ant-modal-body::-webkit-scrollbar-thumb {
  background-color: #1a7f93;
}

.select-conatct_modal .ant-modal-body::-webkit-scrollbar-track,
.select-conatct_modal .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

.modal_tablebox {
  margin-top: 10px;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;