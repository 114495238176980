.dependent_main {

  tr {
    td:last-child {
      display: flex;
      justify-content: end;
    }
  }

  .ant-collapse {
    border: 1px solid #d9d9d9 !important;
    margin-bottom: 10px;

    .ant-collapse-header {
      background: #f5f5f5;

      .ant-collapse-header-text {
        color: #0099e5;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .ant-tag-geekblue {
    color: #000000;
    background: #f5f5f5;
    border-color: #f5f5f5;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
}

.bg-grey {
  p {
    background-color: whitesmoke;
    padding: 12px 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #d9d9d9;
  }
}



.ant-layout-content {
  overflow: hidden;
}

.dependent_main {
  .ant-collapse-expand-icon {
    top: 0;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;