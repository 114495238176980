.activity_table {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;


  tr {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    td {
      p {
        margin: 0;
      }
    }


    td:nth-child(2) {
      span {
        font-size: 3rem;
        margin-top: -14px;
      }
    }
  }

  tr:nth-child(1) {
    td:nth-child(2) {
      span {
        color: #52C41A;
      }
    }
  }

  tr:nth-child(2) {
    td:nth-child(2) {
      span {
        color: #52C41A;
      }
    }
  }


  tr:nth-child(3) {
    td:nth-child(2) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(4) {
    td:nth-child(2) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(5) {
    td:nth-child(2) {
      span {
        color: #FF4D4F;
      }
    }
  }

  tr:nth-child(10) {
    td:nth-child(2) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(8) {
    td:nth-child(2) {
      span {
        color: #FF4D4F;
      }
    }
  }


  tr:nth-child(4) {
    td:nth-child(2) {
      span {
        color: #0099E5;
      }
    }
  }



}

.ant-modal-content {

  // margin-top: 10rem;
  .ant-modal-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }

  .modal_heading {
    display: flex;
    gap: 14px;

    img {
      width: 32px;
      height: 32px;
    }

    .modal_title {
      display: flex;
      gap: 12px;


      p:nth-child(1) {

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0;
      }

      p:nth-child(2) {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.45);
        margin-top: 4px;
      }
    }





  }

  .modal_textarea {
    margin-top: 10px;

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 8px;
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;