.label_spans {
  white-space: pre-wrap;
}

label {
  height: unset !important;
  white-space: initial !important;
}


.textarea_row {
  .ant-form-item-row {


    align-items: unset;

  }
}

.item_replace_traveler {
  #basic_travelers {
    display: flex;
    flex-direction: column;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;