.inventory_popup{
    margin-left: -8px;
}

.anchors_flex{
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 14px;

    @media screen and (max-width: 576px) {
        justify-content: end;
    }
}
.set_hold{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    background: #F7F9FC;
    height: 30px;
    margin-bottom: 27px;
    padding: 0 12px;

    .set_heading{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: #454B57;
        margin: 0;
    }
}

.button_primary{
    margin-top: 10px;
    display: flex;
    justify-content: end;
}

.staffhold_delete{
    &:hover{
        color: red;
    }
}

.center_align {
  position: relative;
  top: 8px;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;