.ant-layout {
  .ant-layout-header {
    box-shadow: inset 0px -1px 0px #f0f0f0;
    height: 75px;
    margin-left: auto;
    width: 100%;

    .horizontal_menu {
      ul {
        background: #f0f2f5;
        box-shadow: inset 0px -1px 0px #f0f0f0;
      }
    }
  }
}


.edit_breadcrumb {
  margin-top: 0rem !important;

  ol {
    li:nth-child(3) {
   
      color: rgba(0, 0, 0, 0.85);

      .ant-breadcrumb-separator {
        display: none;
      }
    }
  }
}

.ant-breadcrumb ol {
  li:nth-last-child(1) {

    span.ant-breadcrumb-link {
      color: rgba(0, 0, 0, 0.85) !important;
      font-weight: 400 !important;
    }

  }
}

.transparent-bg {
  background-color: transparent;
}




@primary-color: #1A7F93;@processing-color: #1A7F93;