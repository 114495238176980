.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #e9e9e9;
  }

// .card__main{
//     img{
//         width: 60px;
//     }
// }  

// .language_main{
//     .ant-tag{
//         font-size: 14px;
//         padding: 5px 22px;
//         border-radius: 20px;
//         border: 1px solid grey;
//         margin: 6px 4px;
    
        
//     }
//     .ant-tag-checkable-checked {
//         border: 1px solid #4EC4F8;
//         background-color: #E6F7FE;
//         color: #4EC4F8;
//         padding: 5px 22px;
//         border-radius: 20px;
      
//     }

// }
// .card__main{
    
//     display: flex;
//     gap: 10px;
//     justify-content: space-between;
//     .card_div{
//         .active{
//             border: 1px solid #4EC4F8;
//                 background-color: #E6F7FE;
//                 color: #4EC4F8;
//         }
//         border: 1px solid grey;
//         background-color: #fff;
//         color: #000;
//         border-radius: 10px;
//         text-align: center;
//         padding: 10px 0px;
//         width: 100%;
//         &:hover{
//             border: 1px solid #4EC4F8;
//             background-color: #E6F7FE;
//             color: #4EC4F8;
//         }
//         .icon{
//             font-size: 30px;
//         }
//         p{
//             margin: 0px;
//         }
//     }

// }



@primary-color: #1A7F93;@processing-color: #1A7F93;