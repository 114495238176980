
.report_elitedetails{
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    width: fit-content;
    .ant-input-group-wrapper{
    width: 500px;
    margin-right: 50px;
    }
}

@media screen and (max-width: 1280px) {
    .report_elitedetails{
        display: block;
        .ant-input-group-wrapper{
            width: 290px;
            margin-bottom: 20px;
            }
            .ant-checkbox-wrapper{
                margin-bottom: 40px;
            }
    }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;