.Res_tables {
  .ant-table-wrapper::before {
    display: none;
  }

  .ant-table-tbody {
    display: none;
  }

  .ant-table-thead>tr>th {
    border-bottom: none;
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }
}

.ant-table-thead>tr>th {
  padding: 1rem !important;
}

.travellers-wrapper tr:has(.disable_traveler) {
  background-color: rgba(204, 204, 204, 0.3);
  // pointer-events: none;
  opacity: 0.4;
}

@media (min-width: 1430px) {
  .assignee-staff {
    .ant-form-item-row {
      align-items: center !important;
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      position: absolute;
      left: -8px;
    }
  }
}

.payment-credit-form {
  .ant-input-number {
    width: 100%;
  }

  .ant-form-item-control-input-content {
    width: 100%;

    .ant-space-horizontal {
      width: 100%;

      .ant-space-item {
        width: 50%;
      }
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;