.ant-layout-content {
  .ant-table-tbody {
    font-family: "Poppins", sans-serif;

    .ant-table-row {
      &:hover {
        .ant-table-cell:nth-child(2) {
          p:nth-child(1) {
            // color: #1A7F93;
          }
        }

        background: #f0f0f0;
      }

      .ant-table-cell:nth-child(2) {
        width: unset;

        p {
          margin: 0 !important;
        }
      }

      .anticon-edit {
        width: 40px;
        height: 40px;
        display: grid;
        place-content: center;


        &:hover {
          background-color: #f0f0f0;
          padding-bottom: 11px;
          border-radius: 50%;
        }
      }

      .anticon-delete {
        width: 40px;
        height: 40px;
        display: grid;
        place-content: center;

        &:hover {
          background-color: #f0f0f0;

          padding-bottom: 11px;
          border-radius: 50%;
          display: grid;
          place-content: center;
        }
      }
    }

    tr {
      height: 50px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      // padding-top: 0 !important;
      // padding-bottom: 0 !important;
      font-family: "Poppins", sans-serif;

      &:hover {
        @media screen and (min-width: 768px) {
          .table_actions {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      td {
        min-height: 50px !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        // padding-top: 0 !important;
        // padding-bottom: 0 !important;
        font-family: "Poppins", sans-serif;

        p {
          margin: 0 !important;
        }
      }
    }
  }
}

.sort_fixing {
  margin-top: 20px;

  .ant-table-pagination {
    margin: 16px 0;
    width: fit-content;
    float: right;
    margin-top: -47px;
  }
}

.action_hover {
  width: 40px;
  height: 40px;

  &:hover {
    background-color: #f0f0f0;
    padding-bottom: 11px;
    border-radius: 50%;
  }

}

.heading_four {
  margin-left: 7px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
}

.ant-table table tr {
  background-color: #fff;
}

.modal_table {
  tbody {
    tr {
      height: 50px;
      padding-bottom: 0;

      &:hover {
        .table_actions {
          display: block;
        }
      }
    }

    td {
      padding-top: 0;
      padding-bottom: 0;
      height: 50px;

      p {
        margin: 0;
      }
    }
  }
}

.contact-table {
  .ant-pagination:nth-child(1) {
    margin-top: -45px;
  }
}

.ant-table-tbody>tr.ant-table-row-selected>td {
  background-color: #fff;
}


.table_actions {

  .edit_color {
    color: gray;
    font-size: 18px;
    padding-top: 11px;
    padding-bottom: 14px;

    &:hover {
      color: #1A7F93;


    }
  }

  .delete_color {
    color: gray;
    font-size: 18px;
    padding-top: 11px;
    padding-bottom: 14px;
    margin-left: 11px;

    &:hover {
      color: red;
    }
  }

  @media screen and (min-width: 768px) {
    opacity: 0;
    visibility: hidden;
  }
}


.mail_padding {
  padding-right: 10px;
}


// .label_spans {
//   white-space: pre-wrap;

//   &::after {
//     display: none;
//   }
// }



// tr {
//   &:hover {
//     .table_actions {
//       opacity: 1;
//       visibility: visible;
//     }
//   }
// }


.paragraph_margin {
  margin: 0;
  color: #1A7F93;
}

.span_bold {
  font-weight: bolder;
}

.heading_center {
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: center;
  }
}

.image_attach {
  margin-top: -30px;
}

.task_name {
  display: flex;
  gap: 30px;
}

.staffhold_anchor {
  color: #454B57;
  font-weight: bolder;
}


.table__height {
  .ant-table-tbody {
    tr {

      td {
        margin: 0;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

.table_filter {
  position: relative;
  z-index: 10;
  width: fit-content;
}


.duplicate_heading {
  h3 {
    margin-bottom: 1rem;
    line-height: 22px;
    font-weight: 500;
    font-family: Poppins;
    color: rgba(0, 0, 0, 0.85);
  }
}

.duplicate_page {
  min-height: 100 !important;
}

.duplicate-table__heading {
  margin-bottom: 1rem;
  line-height: 22px;
  font-weight: 500;
  font-family: Poppins;
  color: rgba(0, 0, 0, 0.85);
}

.duplicate_searchbox {
  margin-top: -20px;
}

.minheight_hidden {
  margin-bottom: 150px;
}

.drawer__label {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.paragraph_marg {
  margin: 0;
}

.vaccination-image {


  max-width: 100px;
  height: auto;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    object-fit: cover;
  }

  &:hover {



    .vaccination-image__content {
      visibility: visible;
      opacity: 0.5;

    }
  }



  &__content {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: #000;


    .anticon-eye {
      color: rgba(255, 255, 255, .65);

      &:hover {
        color: #fff;
      }
    }
  }
}

.medical-details__table {
  table {
    tbody {
      tr {
        td:first-child {
          width: 700px;
        }
      }
    }
  }
}

.span_color {
  color: rgba(0, 0, 0, 0.85);
}

.marketing__collapse {
  border-bottom: 1px solid #d9d9d9;
  ;
}


.marketing_tree {
  // margin-top: 20px;

  .ant-tree-treenode-leaf-last:first-child {
    width: 100%;
    // background-color: #F5F5F5;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;

    .ant-tree-switcher {
      background-color: unset;
      color: #1A7F93;
      display: flex;
      align-items: center;
      padding-left: 6px;
    }


  }

}

.ant-tree-list-holder-inner>.ant-tree-treenode:nth-child(1) {
  background-color: #F5F5F5;
  margin: 0 !important;
}

.imc_concert {
  margin-top: -20px;
}

.inspiration_edit {
  display: flex;
  gap: 18px;
  align-items: center;

  p {
    margin: 0;
  }

  span {
    color: gray;
    width: 2rem;
    height: 30px;
    display: grid;
    place-content: center;

    &:hover {
      background-color: #dfdfdf;
      color: #1A7F93;
      border-radius: 50%;
    }
  }



}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: unset;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: unset;
}

.main-collapse {
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    color: #454B57;
  }
}

.main-collapse--button {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.end-between {
  display: flex;
  justify-content: space-between;
}

.full_table {
  margin: 0;
  padding-top: 20px;
}

.button_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}


.four_location {
  cursor: pointer;
}

.main_logo {
  width: 135px;
  padding-top: 10px;
  object-fit: cover;
}

.logo_two {
  width: 50%;
}

.button {
  width: 85px;
  /* set a width so it doesnt change upon hover */
  background-color: rgba(26, 127, 147, 0.2);
  color: #1A7F93;
  border-color: rgba(26, 127, 147, 0.2);
  color: #1A7F93;
  font-size: 12px;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.button:hover span {
  display: none
}

.button:hover:before {
  content: "Unmark Payee";
  font-size: 10px;
}

.table__border {
  border: 1px solid #f0f0f0;
  padding: 10px;

  .ant-table-thead {
    tr {
      th {
        background-color: #fafafa;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.mark_payee {
  width: 150px;
  /* set a width so it doesnt change upon hover */
  background-color: #fff;
  color: #000;
  border-color: unset;
  border: 1px solid #D9D9D9;
  padding: 3px 21px;
  font-size: 12px;
  text-decoration: none;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}

.form_margin {
  margin: 0;
}

.delete__icon {
  color: red;
}

.table-bord {
  border: 1px solid #f0f0f0;

  .ant-table-thead {
    tr {
      th {
        background-color: #fafafa;
      }
    }
  }
}

.button_bottom {
  margin-top: 16px;
  width: 100%;

  Button {
    text-align: start;
  }
}

.drag_icon {
  .anticon-holder {
    position: absolute;
    left: -46px;
    top: 28%;
  }
}


.asterick-align {
  .ant-form-item-row {
    align-items: unset;
  }

  .ant-form-item-label {
    margin-top: 5px;
  }

  // .ant-form-item-has-error {
  //   margin-bottom: 60px;
  // }

  // .ant-form-item-control-input + div {
  //   position: absolute;
  //   z-index: 100;
  // }
}


.book__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.span_text_two {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.today_end {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  position: relative;
  top: -10px;
}


.card_one {
  height: 100%;
}


.events-header {
  background: #fff;
  margin-top: -24px;
  margin-left: -24px;
  padding-left: 24px;
  margin-bottom: 24px;
  margin-right: -24px;
  padding-right: 24px;
}

.breadcrumb_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0;
  }
}

.create-report {
  background-color: #fff;
  padding: 16px 24px;
  margin: 0 -24px;
}

.table_filter {
  margin-bottom: 1rem;
}

.header_res {
  display: flex;
  gap: 7px;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin: 0;
    font-size: 20px;
    line-height: 28px;
  }
}

.breadcrumb_heading {
  padding-bottom: 1rem !important;

  h3 {
    margin: 0;
    font-size: 20px;
    line-height: 28px;
  }
}

.details_one_tabs {
  .tablist {
    padding-left: 8px;
    padding-bottom: 0;
    margin: 0;
  }
}

.button_bottom {
  .ant-dropdown-trigger {
    width: 100%;

    .ant-space {
      width: 100%;

      .ant-space-item {
        width: 100%;
      }
    }
  }
}

.select_header {
  width: 250px;

  .ant-form-item {
    margin-bottom: 0;
  }
}

.contact-filters {
  margin-bottom: 12px;

  .ant-tag {
    b {
      color: #6F7276;
      font-weight: 400;
    }
  }
}

.filter-applied--wrapper {
  display: flex;
  align-items: self-start;
  gap: 1rem;
  margin-bottom: 12px;

  .filter-title {
    white-space: nowrap;
  }

  >div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}


.travelers--form {

  >div {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }

  .traveler_form_second {
    // display: flex;
    // justify-content: end;
  }

  .travelers-checkbox--list {
    width: fit-content;

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      width: unset !important;

      .ant-checkbox-wrapper {
        margin: 0;
        padding: 0;
      }
    }
  }

  .travelers--form_label {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Poppins";
    color: #000;
    opacity: 0.8;
    display: flex;
    align-items: center;
    width: 170px;
    justify-content: end;
  }

  .ant-row {
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .ant-select {
    width: 325px;
  }

  input {
    width: 325px;
  }
}

@media (max-width: 1399.98px) {
  .travelers--form {
    .ant-select {
      width: 250px;
    }

    input {
      width: 250px;
    }
  }
}

.category_pricing .ant-table-cell {
  white-space: nowrap;
}

.ant-table-wrapper {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    // &-track {
    //   background-color: #bbb;
    // }

    &-thumb {
      background-color: #1a7f93;
    }

    &-track,
    &-thumb {
      border-radius: 12px;
    }
  }
}




.ict-custom-accordion--header {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    // &-track {
    //   background-color: #bbb;
    // }

    &-thumb {
      background-color: #247f93 !important;
    }

    &-track,
    &-thumb {
      border-radius: 12px;
    }
  }
}

.Res_tables .ant-table-cell {
  white-space: nowrap;
}

.search_contact_modal {
  .anticon-filter {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: grid;
    place-content: center;
    margin-top: -3px;
  }
}

.description-table {
  .ant-table-thead {
    tr {
      th:last-child {
        width: 18%;
      }
    }
  }
}

.phone_row {
  white-space: nowrap;
  gap: 2px !important;

  .anticon-phone {
    margin-top: 9px;
  }
}

// .transaction-table {
//   .ant-table-thead {
//     tr {
//       th:nth-child(1) {
//         width: 300px;
//       }
//     }
//   }
// }

.transactions-wrpper {
  display: flex;
  gap: 10px;
  align-items: center;

  .ant-input {
    max-width: 150px;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;