.ant-picker {
  width: 100%;
  padding: 0px 10px !important;
}

.border_container {
  border-left: 2px solid whitesmoke;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .border_container {
    border-left: none;
  }

}

.phone-prefix {
  .ant-select-selector {
    background: #FAFAFA !important;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;