.dash-section-two {
  position: relative;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

.reservation_abs {
  position: absolute;
  right: 0;
  top: -99px;

  @media screen and (min-width: 768px) {
    top: -55px ; 
  }
}

// .c3-shape-0 {
//   fill: rgb(10, 49, 35) !important;
// }
// .c3-shape-1 {
//   fill: rgb(0, 62, 95) !important;
// }
// .c3-shape-2 {
//   fill: rgb(223, 123, 0) !important;
// }

// .c3-shape-3 {
//   fill: rgb(0, 163, 158) !important;
// }

// .c3-shape-4 {
//   fill: rgb(0, 163, 158) !important;
// }
// .c3-shape-5 {
//   fill: rgb(0, 163, 158) !important;
// }

// .c3-shape-6 {
//   fill: rgb(0, 163, 158) !important;
// }


@primary-color: #1A7F93;@processing-color: #1A7F93;