.updated_icon {
  text-align: center;
  margin-bottom: 48px;
}

.updateform_main {
  .update_form {
    h2 {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      text-align: center;
    }

    div {
      text-align: center;
      margin-bottom: 2px;
    }

    p {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
      margin: 0;
      text-align: center;
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;