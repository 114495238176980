
    .ant-layout-header {
        box-shadow: inset 0px -1px 0px #F0F0F0;
        height: 48px;

        .horizontal_menu {
            ul {
                background: #F0F2F5;
                box-shadow: inset 0px -1px 0px #F0F0F0;
            }
        }
    }
    .partners_informations {
        .ant-card-body {
            overflow: auto;
        }
    }

    .addnote_breadcrumb {
        margin-top: 0;
      
        ol {
          li:nth-child(4) {
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
      
            .ant-breadcrumb-separator {
              display: none;
            }
          }
        }
      }
@primary-color: #1A7F93;@processing-color: #1A7F93;