* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Poppins" !important;
  margin: 0;
  padding: 0;
}

.eqjs-menu-levelDiv {
  border: 1px solid silver;
  border-radius: 0 0 4px 4px;
  padding: 6px 0;
  box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.16);
}

.eqjs-menu-itemDiv {
  min-width: 158px;
  font-size: 0.9rem;
}

.eqjs-menu-itemDiv:hover {
  background-color: #ecf3f5 !important;
  color: #1776b1;
}

.eqv-columns-title {
  font-family: "Poppins" !important;
  box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0 !important;
}

#EntitiesPanel {
  font-family: "Poppins" !important;
}

.eqjs-ep-entity-node-label {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85) !important;
}

.eqv-entities-title {
  font-family: "Poppins";
  color: #454b57;
  font-weight: 500;
  border-bottom: none;
  background-color: #fafafa !important;
}

.eqv-columns-title {
  font-family: "Poppins" !important;
  color: #454b57;
  font-weight: 500;
  border-bottom: none;
  background-color: #fafafa;
}

.eqjs-addrow a {
  border: 1px dashed #1a7f93;
  color: #1a7f93;
  text-align: center;
  font-family: "Poppins" !important;
  padding: 4px 15px;
  position: relative;
  font-weight: 400;
}

.eqjs-addrow a::before {
  content: "\2b";
  font-family: "FontAwesome";
  padding-right: 10px;
  font-weight: 500;
}

.eqv-conditions-title {
  font-family: "Poppins" !important;
  color: #454b57;
  font-weight: 500;
  border-bottom: none;
  box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0 !important;
  background-color: #fafafa;
  height: 3rem;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.eqjs-qp-grelement {
  color: #000000;
  font-weight: 400;
}

.eqjs-qp-grelement a {
  color: #1a7f93;
}

.eqv-sql-panel-title {
  font-family: "Poppins" !important;
  font-weight: 500 !important;
  border-bottom: none !important;
  box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0 !important;
  background-color: #fafafa;
  height: 3rem;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

#QueryPanel a {
  color: #1a7f93;
  text-decoration: underlines;
}

.icon_color {
  color: rgba(0, 0, 0, 0.85);
}

.execute_button {
  padding: 4px 13px;
  background-color: #1a7f93;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-family: "Poppins" !important;
  border: none;
  font-weight: 400;
}

.eqv-result-panel-title {
  font-family: "Poppins" !important;
  color: #454b57 !important;
  font-weight: 500 !important;
  border-bottom: none !important;
  box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0 !important;
  background-color: #fafafa;
  height: 4rem;
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: unset !important;
  justify-content: space-between;
  padding-left: 2rem;
}

.eqjs-export {
  color: rgba(0, 0, 0, 0.85) !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  border: none !important;
  font-size: 14px !important;
  position: relative;
  margin-right: 40px !important;
}

.eqjs-export:first-child::before {
  position: absolute;
  /* content: "\2b"; */
  content: "\f31c";
  font-family: "FontAwesome";
  padding-right: 10px;
  font-weight: 500;
  left: -20px;
  top: 4px;
}

.eqjs-export:last-child::before {
  position: absolute;
  /* content: "\2b"; */
  content: "\f0ed";
  font-family: "FontAwesome";
  padding-right: 10px;
  font-weight: 500;
  left: -25px;
  top: 4px;
}

input[type="checkbox"] {
  accent-color: #1a7f93;
}

/* .eqjs-ep-tool-panel-add-cond{
      background-image: none !important;
      position: relative;
  } */
/* 
  .eqjs-ep-tool-panel-add-cond::before{
      position: absolute;
  
      content: "\f0ed";
      font-family: "FontAwesome";
      padding-right: 10px;
      font-weight: 500;
      left: -25px;
      top: 4px;
  } */

.eqjs-ep-entity-attr-label {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85) !important;
  padding: 10px !important;
}

.eqjs-ep-entity-attr:hover {
  background-color: #1a7f93;
  color: #fff !important;
}

.eqjs-ep-entity-attr:hover .eqjs-ep-entity-attr-label {
  color: #fff !important;
  font-weight: 400;
  font-family: "Poppins" !important;
}

.eqjs-addrow>a:hover {
  border-bottom: 1px dashed !important;
}

.eqjs-menu-itemDiv:hover {
  background-color: #1a7f93 !important;
  color: #fff !important;
  font-weight: 400;
  font-family: "Poppins" !important;
}

.eqjs-menu-itemDiv {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85) !important;
}

.eqv-conditions-block {
  border: none !important;
}

.eqv-entities-block {
  border: none !important;
}

.eqv-columns-block {
  border: none !important;
}

.eqv-conditions-block {
  border: none !important;
}

.eqv-sql-panel {
  border: none !important;
  box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0 !important;
}

.eqv-result-panel {
  border: none !important;
  box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0 !important;
}

.eqv-entities-panel-container {
  padding: 10px !important;
}

.eqjs-ep-entity-node {
  box-shadow: inset 0px -0.5px 0px #f0f0f0, inset 0px 0.5px 0px #f0f0f0 !important;
  border-bottom: none !important;
  height: 32px !important;
}

.eqjs-ep-entity-attr {
  height: 32px !important;
  display: flex !important;
  align-items: center !important;
}

.eqjs-qp-row-group-root {
  padding-top: 10px !important;
}

.eqjs-qp-row-group-root:hover {
  background-color: unset !important;
}

.border_flexone {
  display: flex;
  gap: 25px;
}

.eqv-dropdown-container {
  width: unset !important;
}

.eqv-menu-content {
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
}

.eqjs-qp-panel {
  background-color: #fff !important;
}

.eqjs-addrow-empty {
  background-color: #fff;
}

#EntitiesPanel {
  background-color: #fff;
}

/* .eqjs-qc-columns {
    display: none;
  } */

.eqjs-ep-tool-panel-left-side {
  width: unset !important;
}

#eqv-content {
  background-color: #f0f2f5 !important;
  border: none !important;
  padding: 24px 0 !important;
}

.two {
  padding-top: 10px !important;
}

.eqjs-ep-tool-panel-right-side {
  display: flex !important;
  justify-content: end !important;
}

/* Custom Header CSS */

.customheader {
  background-color: #fff;
  padding: 16px 24px;
  margin-bottom: 24px;
  margin-left: -24px;
  margin-right: -24px;
}

.customheader_content {
  display: flex;
  justify-content: space-between;
}

.customheader p {
  color: rgba(0, 0, 0, 0.45);
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.customheader p span {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
}

.customheader_content--icons {
  display: flex;
  gap: 18px;
  align-items: center;
  padding-top: 14px;
}

.customheader_content--icons p {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
}

.customheader_content--icons i {
  color: rgba(0, 0, 0, 0.85);
}

.customheader_content--buttons {
  display: flex;
  gap: 8px;
}

.button_one {
  padding: 4px 15px;
  border: 1px solid #1a7f93;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  background: #1a7f93;
  font-family: "Poppins" !important;
  font-style: normal;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.button_two {
  padding: 4px 15px;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  background: #fff;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
}


.eqjs-menu-levelDiv {
  /* left: 812px; top: 193px; z-index: 10000; font-size: 14px; */
  border: 1px solid silver;
  border-radius: 0 0 4px 4px;
  padding: 6px 0;
  box-shadow: 4px 8px 15px 0px rgba(0, 0, 0, 0.16);
}

.eqjs-menu-itemDiv {
  min-width: 158px;
  font-size: 0.9rem;
}

.eqjs-menu-itemDiv:hover {
  background-color: #ecf3f5 !important;
  color: #1776b1;
}

/* body,
  p,
  td,
  th,
  div {
    font-family: Trebuchet MS, Tahoma, Verdana, Geneva, Arial, Helvetica,
      sans-serif;
    font-size: 11px;
    margin: 0;
    padding: 0;
  } */

.report_breadcrumb {
  padding: 0px 24px;
  /* padding-top: 16px; */
}

.report_heading {
  margin-bottom: 0 !important;
  padding-bottom: 0;
  margin-left: -24px;
  margin-right: -24px;
}

.eqjs-qc-attrelement a {
  color: #1A7F93;
}

.eqjs-qc-captionelement a {
  color: #1A7F93;
}

.eqjs-qp-attrelement a {
  color: #1A7F93 !important;
}

.eqjs-qp-operelement a {
  color: #1A7F93 !important;
}

.eqjs-qp-valueelement a {
  color: #1A7F93 !important;
}

.icon_font {
  font-size: 14px;
}

.eqjs-menu-scrollDiv .eqjs-menu-itemDiv:hover {
  background-color: #1a7f93 !important;
  color: #fff !important;
  font-weight: 400;
  font-family: "Poppins" !important;
}

.eqv-dropdown-show a:hover {
  background-color: #1a7f93 !important;
  color: #fff !important;
  font-weight: 400;
  font-family: "Poppins" !important;
}

.execute_button:hover {
  color: #fff !important;
  border-color: #3793a1 !important;
  background: #3793a1 !important;
}

.eqjs-ep-entity-node {
  display: flex !important;
  align-items: center !important;
}

.eqjs-ep-entity-node-label input {
  top: 0 !important;
}

.eqjs-ep-entity-node-label {
  display: flex !important;
}

.create-report__padding {
  padding-bottom: 0 !important;
}


.ant-layout:has(.create-report-section) .layout-content {
  max-width: 100%;
  transform: unset;
}


.create-report-section .eqjs-ep-panel>div.eqjs-ep-entity {
  overflow: scroll !important;
}

.create-report-section .eqjs-ep-panel>div.eqjs-ep-entity::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.create-report-section .eqjs-ep-panel>div.eqjs-ep-entity::-webkit-scrollbar-track {
  background-color: #bbb;
}

.create-report-section .eqjs-ep-panel>div.eqjs-ep-entity::-webkit-scrollbar-thumb {
  background-color: #1a7f93;
  height: 3rem;
}

.create-report-section .eqjs-ep-panel>div.eqjs-ep-entity::-webkit-scrollbar-track,
.create-report-section .eqjs-ep-panel>div.eqjs-ep-entity::-webkit-scrollbar-thumb {
  border-radius: 12px;
}

.create-report-section .eqjs-qc-colelement,
.create-report-section .eqjs-qp-grelement,
.create-report-section .eqjs-qc-header-expression,
.create-report-section .eqjs-qc-header-title {
  font-family: "Poppins" !important;
}

.create-report-section .eqv-sql-panel-result {
  font-family: "Poppins" !important;
  font-size: 14px;
}

.report_page iframe {
  border: unset;
}