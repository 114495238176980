.icon_change {
  .ant-table-tbody tr td:nth-child(1) {
    // font-weight: 600 !important;
    width: 16%;
  }

  .ant-table-row-expand-icon {
    font-size: 20px;
    background-color: transparent;
    border: none;
    font-weight: 600;
  }

  .ant-table-row-expand-icon::after {position: absolute;
    background: none !important;
    transition: transform 0.1s ease-out;
    content: ">";
    display: inline-block;
    position: absolute;
    top: -6%;
    left: 50%;
    margin-left: -0.7em;
    width: 1.4em;
    height: 1.4em;
    line-height: 1.3em;
  }

  .ant-table-row-expand-icon-collapsed::before {
    display: none;
  }

  .ant-table-row-expand-icon::before {
    display: none;
  }
}

.reservation_main-table {
  .ant-table-row.ant-table-row-level-0 {
    background-color: #f5f5f5;
  }
}

.event_res_table_main {
  position: relative;
  z-index: 10;
  width: fit-content;

  .ant-input-group-wrapper {
    width: 400px;
  }

  .table_search {
    width: 200px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 414px) {
  .event_res_table_main {
    .ant-input-group-wrapper {
      width: 100%;
    }

    .table_search {
      width: 145px;
    }
  }
}

@media screen and (max-width: 575px) {
  .event_res_table_main {
    .table_search {
      margin-left: 0px;
      margin-top: 20px;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .event_res_table_main {
    .ant-input-group-wrapper {
      width: 170px;
    }

    .table_search {
      width: 150px;
      margin-left: 5px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1280px) {
  .event_res_table_main .ant-input-group-wrapper {
    width: 240px;
  }
}




.plus_change {

  .ant-table-row-expand-icon {
    font-size: 20px;
    background-color: transparent;
    border: none;
    font-weight: 600;
    // pointer-events: none;
  }

  .ant-table-row-expand-icon::after {
    position: absolute;
    background: none !important;
    transition: transform 0.3s ease-out;
    content: ">";
  }

  .ant-table-row-expand-icon-collapsed::before {
    display: none;
  }

  .ant-table-row-expand-icon::before {
    display: none;
  }
}

.checbox_space{
  padding-right: 7px;
}

@primary-color: #1A7F93;@processing-color: #1A7F93;