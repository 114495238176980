.ant-tag-geekblue {
  color: #3699E5;
  background: #f0f5ff;
  border-color: #4EC4F8;
}

.ant-tag-volcano {
  color: #12191d;
  background: #FAFAFA;
  border-color: #D9D9D9;
}

.event_cruise_table {



  thead {
    tr {
      th:nth-child(2) {
        width: 30%;

      }

      th:nth-child(5) {
        width: 10%;

      }
    }
  }
}

.margin_zero {
  margin: 0;
}

.padding_text {
  padding-left: 30px;
}

.table_pagination {
  .ant-pagination:first-child {
    margin-top: 0;
  }
}

.filter_pagination {
  margin-bottom: 1rem;
}

.event_listing_table {
  .ant-table-cell {
    padding: 1rem !important;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;