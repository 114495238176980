.heading_three{
    padding-top: 14px;
    margin: 0 !important;
}

.breadcrumb_padding{
    margin-top: -38px;
    padding-left: 33px;
}

.add_intake{
    .ant-dropdown-menu{
        top: 0px;
      }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;