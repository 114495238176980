.requests_title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 0 !important;
  }
}

.task_activity_table {
  tr {
    td:last-child {
      max-width: 500px;
    }
  }
}

.request_table {

  tr {
    td:nth-child(8) {

      .anticon-edit {
        width: 40px;
        height: 40px;
        display: grid;
        place-content: center;

        &:hover {
          background-color: #F0F0F0;
          padding-bottom: 11px;
          border-radius: 50%;
        }
      }


      .anticon-delete {
        width: 40px;
        height: 40px;
        display: grid;
        place-content: center;

        &:hover {
          background-color: #F0F0F0;
          padding-bottom: 11px;
          border-radius: 50%;
          display: grid;
          place-content: center;
        }
      }
    }

    td:nth-child(6) {
      width: 170px;
    }
  }
}

.anticon-info-circle {
  // position: absolute;
  // top: 50%;
  // right: -20px;
  // transform: translateY(-50%);
}

.modal_searchbox {
  margin-right: 25px;
  margin-top: -18px;
}

.delete_btn:hover {
  border-color: red;
  color: red;
}

.select-contact-modal-searchbox {
  margin-right: unset;
}

.select-reservation_searchbox {
  .ant-form-item-row {
    display: flex;
    justify-content: end;
    margin-top: -3.5rem;
  }
}

.addons-filters_wrapper {
  .ant-table-row {
    display: flex;
    flex-direction: column;

    .ant-table-cell {
      border: unset;
      padding: 0;
      margin-bottom: 1rem;

      >div {
        display: flex;
        gap: 1rem;
        align-items: center;

        .document_pdf {
          width: 240px;
          display: flex;
          align-items: center;
          justify-content: end;
          margin: 0;
          padding: 0;
          font-weight: 400;
        }
      }
    }
  }
}

.ant-layout-content .ant-table-tbody tr td {
  padding: 1rem !important;
  border-color: #f0f0f0 !important;
  border-left: unset;
  border-right: unset;
  border-top: unset;
}

.marketing_tree {
  .ant-tree-treenode-switcher-open {
    position: relative;
    top: 12px;
    padding-left: 0;
    margin-left: -3px;
    margin-top: 6px;
  }

  .ant-tree-treenode-switcher-close {
    position: relative;
    top: 12px;
    padding-left: 5px;
  }
}

// .ant-btn-icon-only {
//   width: 25px;
//   height: 25px;
// }


.ant-table-cell {
  padding: 8px 16px !important;
}

.request_table {
  p {
    margin-bottom: 0 !important;
  }
}

.revision_history_table {
  tr {
    vertical-align: top;
  }
}

#basic_full_response_info {
  word-wrap: break-word;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;