@import "~antd/es/style/themes/default.less";
@import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
// @import 'style.less'; // variables to override above
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

// GLOBAL COLORS
@primaryColor: #1a7f93;
@grey: #8c8c8c;
@orange: #ffa940;

iframe#webpack-dev-server-client-overlay{display:none!important}

.ant-dropdown-menu li:hover {
  background-color: @primaryColor !important;
}

.primary_color {
  background-color: rgba(26, 127, 147, 0.2) !important;
  color: @primaryColor !important;
  border-color: rgba(26, 127, 147, 0.2) !important;
}

.ant-picker {
  height: 32px;
}

.payment_complete {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: green;
    border-radius: 100px;
    top: 40%;
    left: -12px;
  }
}

.payment_design {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: @grey;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    top: 40%;
    left: -12px;
  }
}

.payment_cancel {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: red;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    top: 40%;
    left: -12px;
  }
}
.payment_error {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: black;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    top: 40%;
    left: -12px;
  }
}

.payment_pending {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: @orange;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    top: 40%;
    left: -12px;
  }
}

.additional_panel,
.ant-collapse-arrow {
  color: @primaryColor;
}

//Practice

@primary-color: #1A7F93;@processing-color: #1A7F93;