.ant-drawer-content-wrapper {

    .ant-drawer-content {

        .ant-drawer-wrapper-body {

            .ant-drawer-header {

                .ant-drawer-header-title {
                    .ant-drawer-close {
                    }

                    .ant-drawer-title {

                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        font-family: 'Poppins', sans-serif;


                    }
                }
            }

           
        }
    }
}
.active_class{
    background-color: aqua;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;