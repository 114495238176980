.close_relative{
    position: relative;
    .close_absolute{
        position: absolute;
        right: -60%;
    }
}

@media screen and (max-width: 767px) {
    .close_absolute{
        right: 0 !important;
    }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;