.ant-tabs-content-holder {
  .ant-tabs-content-top {
    .basic_details {
      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              td:first-child {
                // display: inline-block;
                // width: 295px !important;
                width: 500px !important;
                height: 46px !important;
                padding-top: 11px !important;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
              }

              td:last-child {
                // display: inline-block;
                padding-top: 11px !important;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
              }

              &:hover {
                background-color: unset;
              }
            }

            .ant-table-row-level-0:nth-child(13) {
              td:last-child {
                // color: #0099e5;
              }
            }

            .ant-table-row-level-0:nth-child(11) {
              td:last-child {
                // color: #0099e5;
              }
            }
          }
        }
      }

      .Address_card {
        .ant-card-body {
          overflow: auto;
          width: 100%;
        }

        .ant-anchor-fixed {
          position: unset;
        }
      }
    }

    .reservation_details {
      padding: 16px 24px;

      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 16px;
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }
    }

    .container_spacing {
      padding: 16px 24px;
    }

    .content_heading {
      margin-left: 7px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 16px;
    }

    .activity_details {
      padding: 16px 24px;

      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 16px;
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }
    }

    .request_details {
      border: 24px solid #f0f2f5;
      margin-left: -24px;
      margin-right: -24px;
      margin-top: -16px;
      padding: 16px 24px;

      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 16px;
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }
    }

    .transfer_details {
      border: 24px solid #f0f2f5;
      margin-left: -24px;
      margin-right: -24px;
      margin-top: -16px;
      padding: 16px 24px;

      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 16px;
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }
    }

    .request_details {
      border: 24px solid #f0f2f5;
      margin-left: -24px;
      margin-right: -24px;
      margin-top: -16px;
      padding: 16px 24px;

      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 16px;
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }
    }

    .product_history {
      padding: 16px 24px;

      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 16px;
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }
    }

    .settings_details {
      padding: 16px 24px;
      // margin-bottom: 20px;

      h4 {
        margin-left: 7px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 16px;
      }

      .ant-table-wrapper {
        .ant-spin-nested-loading {
          .ant-table-tbody {
            tr {
              &:hover {
                background-color: unset;
              }
            }
          }
        }
      }

      // &--system {
      //   height: 50vh;
      // }
    }
  }
}

.view_breadcrumb {
  padding: 0 !important;
}

.details-tabs {
  margin-top: -25px;

  .ant-tabs-nav {
    background-color: #fff;
    padding: 0 24px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 24px;

    @media screen and (max-width: 675px) {
      margin-left: 0px;
      margin-right: 0px;
      padding: 0 16px;
    }
  }

  .basic_details {
    background-color: #fff;
    padding: 16px 24px;
    margin-bottom: 24px;
  }
}

.bg-white {
  background-color: #fff;
}

// .reservation_details > div {
//   overflow: auto;
//   white-space: nowrap;
// }

@media screen and (max-width: 675px) {
  .pd-16 {
    padding: 16px 24px 16px 16px !important;
  }

  .ant-card-body {
    padding: 16px;
  }
}

.medical__table {
  padding: 16px 24px;
  margin-bottom: 20px;

  h4 {
    margin-left: 7px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 16px;
  }

  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-table-tbody {
        tr {
          &:hover {
            background-color: unset;
          }
        }
      }
    }
  }

  &--system {
    height: 50vh;
  }
}


.tabs_style .ant-table-wrapper .ant-spin-nested-loading .ant-table-tbody tr td:last-child {
  max-width: 350px;
}

.general_information_table {
  .ant-spin-nested-loading {
    .ant-spin-container {
      .ant-table-container {
        .ant-table-content {
          .ant-table-tbody {
            tr {
              td:first-child {
                width: 200px !important;
                height: 46px !important;
                padding-top: 11px !important;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
              }
            }
          }
        }
      }
    }
  }
}



// @media (min-width: 1400px) {
//   .total_table {

//     .ant-spin-nested-loading {
//       .ant-table-tbody {
//         tr {
//           background-color: #FAFAFA;

//           td:first-child {
//             width: 485px;
//             padding-left: 4rem;
//           }

//           td:last-child {
//             display: flex;
//             justify-content: center;
//           }
//         }

//       }
//     }
//   }
// }


// @media (min-width: 1500px) {
//   .total_table {

//     .ant-spin-nested-loading {
//       .ant-table-tbody {
//         tr {
//           background-color: #FAFAFA;

//           td:first-child {
//             width: 530px;
//             padding-left: 4rem;
//           }

//           td:last-child {
//             display: flex;
//             justify-content: center;
//           }
//         }

//       }
//     }
//   }
// }

// @media (min-width: 1600px) {
//   .total_table {

//     .ant-spin-nested-loading {
//       .ant-table-tbody {
//         tr {
//           background-color: #FAFAFA;

//           td:first-child {
//             width: 575px;
//             padding-left: 4rem;
//           }

//           td:last-child {
//             display: flex;
//             justify-content: center;
//           }
//         }
//       }
//     }
//   }
// }

// @media (min-width: 1700px) {
//   .total_table {

//     .ant-spin-nested-loading {
//       .ant-table-tbody {
//         tr {
//           background-color: #FAFAFA;

//           td:first-child {
//             width: 650px;
//             padding-left: 4rem;
//           }

//           td:last-child {
//             display: flex;
//             justify-content: center;
//           }
//         }

//       }
//     }
//   }
// }

.anchor_weight_black {
  font-family: 'Poppins';
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.85) !important;
}

.section_gap {
  height: 18px;
  width: 100%;
  background-color: #f0f2f5;
}

.table_automatic .ant-spin-nested-loading .ant-table-tbody tr:has(.accomodation_main_heading) {
  background-color: #F5F5F5;
  position: relative;
  height: 3rem;
}

.table_automatic .ant-spin-nested-loading .ant-table-tbody tr:has(.accomodation_main_heading) td:nth-child(2) {
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: unset;
}

.table_automatic .ant-spin-nested-loading .ant-table-tbody tr:has(.accomodation_main_sub) {
  background-color: #FAFAFA;
  position: relative;
  height: 3rem;
}

.table_automatic .ant-spin-nested-loading .ant-table-tbody tr:has(.accomodation_main_sub) td:nth-child(2) {
  position: absolute;
  left: 0;
  width: 100%;
}

.table_automatic {

  .ant-table-tbody>tr.ant-table-row:hover>td,
  .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background-color: unset !important;
  }
}

.table_automatic {
  .ant-table-thead {
    position: sticky;
    top: 0;
    background-color: #fafafa;
    z-index: 999;
  }
}

.accomodation_main_heading {
  padding-top: 5px;
}


// @media (min-width: 1400px) {
//   .accommodation_scroll_table {
//     height: 53vh;
//     overflow-y: scroll !important;

//     &::-webkit-scrollbar {
//       width: 10px;

//       &-track {
//         background-color: #bbb;
//       }

//       &-thumb {
//         background-color: #1a7f93;
//       }

//       &-track,
//       &-thumb {
//         border-radius: 12px;
//       }
//     }
//   }
// }

// @media (min-width: 1550px) {
//   .accommodation_scroll_table {
//     height: 62vh;
//     overflow-y: scroll !important;

//     &::-webkit-scrollbar {
//       width: 10px;

//       &-track {
//         background-color: #bbb;
//       }

//       &-thumb {
//         background-color: #1a7f93;
//       }

//       &-track,
//       &-thumb {
//         border-radius: 12px;
//       }
//     }
//   }
// }









.table_automatic {
  .ant-spin-nested-loading {
    .ant-table-tbody {
      tr:last-child {
        td {
          background-color: #FAFAFA !important;
        }
      }

      .footer-row {
        position: sticky;
        bottom: -4px;
      }
    }
  }
}

.table_automatic .ant-table-body {
  &::-webkit-scrollbar {
    width: 10px;

    &-track {
      background-color: #bbb;
    }

    &-thumb {
      background-color: #1a7f93;
    }

    &-track,
    &-thumb {
      border-radius: 12px;
    }
  }
}

.optional_accommodation_table {
  .ant-spin-nested-loading {
    .ant-table-tbody {
      tr:first-child {
        height: unset !important;
        min-height: unset !important;

        td {
          min-height: unset !important;
          height: unset !important;
          padding: 0 !important;
        }
      }
    }
  }
}

.select_priority_cta_wrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.full_response .ant-form-item-label {
  margin-top: 0;
}

@media (min-height: 800px) {
  .accommodation_scroll_table {

    .ant-table-body {
      max-height: 390px !important;
    }
  }
}

@media (min-height: 900px) and (max-height: 999.98px) {
  .accommodation_scroll_table {

    .ant-table-body {
      max-height: 490px !important;
    }
  }
}


@media (min-height: 1000px) {
  .accommodation_scroll_table {

    .ant-table-body {
      max-height: 570px !important;
    }
  }
}

.accommodation_scroll_table {
  .ant-table-cell {
    border-bottom: unset;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;