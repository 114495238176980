.convert_intake {
  padding-left: 18px;
}

.interior_table {
  .ant-table-tbody {
    tr {
      border: none;

      td {
        border: none;
      }

      td {
        border: none;
      }

      &:hover {
        .action_flex {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.ant-table {
  background: #fbfbfb;
}


.timeline_class {
  padding-bottom: 0;
  margin-top: 6px;

  .ant-timeline-item-content {
    min-height: 0;
  }

  .ant-timeline-item-tail {
    height: 53px;
  }
}


.action_flex {
  display: flex;
  gap: 18px;
  justify-content: end;
  align-items: center;

  @media screen and (min-width: 767px) {
    opacity: 0;
    visibility: hidden;
  }

  .action_edit {
    font-size: 18px;
    padding-top: 11px;
    padding-bottom: 14px;
    color: gray;
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;

    &:hover {
      background-color: #f0f0f0;
      padding-bottom: 11px;
      border-radius: 50%;
      color: #1A7F93;
    }
  }

  .action_delete {
    font-size: 18px;
    padding-top: 11px;
    padding-bottom: 14px;
    color: gray;
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;

    &:hover {
      background-color: #f0f0f0;
      padding-bottom: 11px;
      border-radius: 50%;
      color: red;
    }
  }
}


.table_tour {
  .ant-table-tbody {
    tr {
      &:hover {
        .action_flex {
          justify-content: end;
          visibility: visible;
          opacity: 1;


        }
      }



    }


  }
}

.Action {
  display: flex;
  // justify-content: end;
}

.action_start {
  display: flex;
  justify-content: start;
}

.adult_class {
  padding-left: 8px;
}

// .button_hover {

//     color: #40a9ff;
//     border-color: #40a9ff;
//     background: #fff;
// }

tr {
  &:hover {
    .action_flex {
      opacity: 1;
      visibility: visible;
      justify-content: end;
    }
  }
}




// .cruise_table {

//     .ant-table-tbody {
//         tr {
//             margin: 0;
//             padding: 0;
//             height: 70px;

//             td {
//                 margin: 0;
//                 padding: 0;
//                 height: 70px;
//             }
//         }
//     }

// }

.action_end {
  justify-content: unset !important;
}



.schedule_table {

  table {
    tbody {
      tr {
        td:nth-child(1) {

          background-color: #fff !important;
        }

        td:nth-child(2) {

          background-color: #fff !important;

        }

        td:nth-child(3) {

          background-color: #fff !important;
        }

        td:nth-child(4) {

          background-color: #fff !important;
        }

        td:nth-child(5) {

          background-color: #fff !important;
        }

        td:nth-child(6) {

          background-color: #fff !important;
        }

        &::hover {
          color: unset !important;
          background-color: #fff !important;


        }
      }

      tr:nth-child(1) {
        background-color: #fff;

        &:hover,
        &:focus,
        &:active {
          background-color: #fff !important;
        }
      }
    }
  }
}

.action_edit {
  font-size: 18px;
  padding-top: 11px;
  padding-bottom: 14px;
  color: gray;
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;

  &:hover {
    background-color: #f0f0f0;
    padding-bottom: 11px;
    border-radius: 50%;
    color: #1A7F93;
  }
}

.action_delete {
  font-size: 18px;
  padding-top: 11px;
  padding-bottom: 14px;
  color: gray;
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;

  &:hover {
    background-color: #f0f0f0;
    padding-bottom: 11px;
    border-radius: 50%;
    color: red;
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;