.hide {
  display: none;
}

.horizontal_menu {
  padding-top: 5px;

  .searchbox {
    padding-top: 12px;
    width: 100%;
    position: relative;

    .search-group {
      max-width: 560px;
      // background-color: #000;
      position: relative;

      .advance-search-dropdown {
        position: absolute;
        text-align: right;
        right: 0;
        height: 100%;
        //  width: 100%;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: end;

        &.ant-dropdown-open {
          width: 100%;
          left: 0px;
        }
      }
    }

    .ant-select-show-search {
      width: 100%;
    }

    button {
      background-color: transparent;
      color: unset;
      opacity: 0.5;
      border-color: white;
      box-shadow: 0 0;
      pointer-events: none;
    }

    .ant-select-clear {
      position: absolute;
      right: 1rem;
      font-size: 14px;
      top: 19px;
      z-index: 999;
      right: 42px;
      color: rgba(0, 0, 0, 0.45);

      svg {
        path {
          d: path("M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
            ) !important;
        }
      }
    }

    .anticon-sliders {
      border-radius: 50%;
      z-index: 999;
      position: relative;

      &.active {
        background-color: rgba(0, 60%, 89.8%, 0.3);
      }
    }

    input {
      // border-left: none;
      padding-left: 55px;
      // border-color: #fff;

      font-family: "Poppins", sans-serif;
      position: relative;
      width: 100%;

      +span {
        position: absolute;
      }

      &:focus {
        // border-color: white;
        // box-shadow: 0px 2px 0px rgb(0 0 0 / 4%);
        // border: 1px solid #d9d9d9;
      }
    }

    .ant-input-group-addon {
      left: 0;
      background-color: transparent !important;
      z-index: 200;
      top: 1px;

      button {
        height: 38px;
      }
    }
  }

  ul {
    display: flex;
    justify-content: end;
    font-family: "Poppins", sans-serif;

    li {
      font-family: "Poppins", sans-serif;

      &:hover {
        color: unset;
        border-bottom: unset;
        opacity: 0;

        &::after {
          display: none;
        }
      }

      span {
        text-decoration: none;
        font-family: "Poppins", sans-serif;

        &:hover {
          border-bottom: unset;
          color: unset;
        }
      }
    }

    li.notification {
      span {
        &::before {
          content: "7";
          position: relative;
          background-color: red;
          width: 20px;
          height: 20px;
          font-weight: 400;
          padding-left: 5px;
          padding-right: 5px;
          font-family: "Poppins", sans-serif;
          color: #fff;
          font-weight: 400;
          font-size: 14px;
          border-radius: 50%;
          left: 27px;
          top: -12px;
        }
      }
    }
  }
}

.ant-dropdown-menu {
  width: 160px;
  top: 10px;

  li {
    &:hover {
      background-color: #4cb5ff;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }
}

.ant-pagination:first-child {
  .ant-pagination-item {
    display: none;
  }
}

.ant-select-dropdown {
  max-width: 560px;
  left: 290px;
}

// .ant-select-dropdown-placement-bottomLeft {
//   width: 390px;
//   position: absolute;
//   left: 27rem;
// }

.ant-drawer-content-wrapper {
  .ant-drawer-content {
    .aside-menu & {
      background-color: #002233;
    }
  }
}

.profile-group {
  display: flex;
  justify-content: end;
}

.mobileview {
  .profile {
    padding-right: 12px;

    .ant-dropdown-trigger {

      .ant-space-item:nth-child(2),
      .ant-space-item:nth-child(3) {
        display: none;
      }
    }
  }

  .notification {
    color: #fff;
    font-size: 20px;
  }

  .search-mobile {
    color: #fff;
    font-size: 21px;
    margin-right: 24px;
  }

  .mainnav-logo {
    margin-left: 8px;
    margin-top: -9px;
  }
}

.ant-layout-has-sider {
  >.ant-layout {
    padding: 0 20px;
    max-width: calc(100% - 215px) !important;
    min-height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    width: 100% !important;
    transform: translateX(214px);

    .mobileview & {
      padding: 0 0;
    }
  }
}

.main-header {
  background: #f0f2f5;

  .mobileview & {
    background-color: #002233;
  }

  .notification {
    margin-right: 38px;
    font-size: 17px;
    position: relative;

    .notification-count {
      position: absolute;
      top: 9px;
      background: #f5222d;
      height: 20px;
      line-height: 1;
      width: 27px;
      color: #fff;
      text-align: center;
      border-radius: 100px;
      padding: 0px 6px;
      left: 10px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .profile {
    a {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .search-group {
    display: flex;
    align-items: center;

    .ant-dropdown-trigger {
      span.anticon {
        padding: 9px;
        opacity: 0.9 !important;
        border-radius: 50%;
        margin-right: 16px;

        svg {
          color: rgba(0, 0, 0, 0.45);
        }

        &.active {
          background-color: rgba(26, 127, 147, 0.2);

          svg {
            color: #1A7F93;
          }
        }
      }
    }
  }
}

.mobileview {
  .logo-group {
    justify-content: space-between;
    padding-top: 5px;

    .ant-col:nth-child(2) {
      margin-left: 10px;
      margin-right: auto;
    }
  }
}

//global changes
.ant-layout-header {
  padding: 0 0;
}

.hamurger-menu-icon {
  font-size: 21px;
  color: #fff;

  padding: 13px;
}

@media only screen and (max-width: 991px) {
  .aside-menu {
    .sidebar-logo {
      display: none;
    }

    .ant-drawer-body {
      padding: 0;
    }

    .ant-drawer-content-wrapper {
      max-width: 300px !important;
    }

    .ant-drawer-header-title {
      display: none;
    }
  }

  .ant-table-content {
    overflow: auto;

    .ant-table-tbody {
      white-space: nowrap;
    }
  }
}

@media only screen and (min-width: 992px) {
  .search-mobile {
    display: none;
  }

  .searchbox {
    button.back-btn {
      display: none;
    }
  }
}

@media only screen and (max-width: 991px) {
  .searchbox {
    visibility: hidden;
    width: 0;
    position: absolute !important;
    right: 0;
    z-index: 9;
    background: #002233;
    padding-right: 10px;
    padding-left: 10px;
    top: 0;
    padding-top: 5px;
    transition: all 0.3s;
    opacity: 0;

    button.back-btn {
      opacity: 1 !important;
      border: 0;
      position: relative;
      z-index: 9;

      span {
        color: #fff;
      }
    }

    &.active-search {
      display: block;
      width: 100%;
      opacity: 1;
      visibility: visible;
      padding-top: 18px;
    }
  }
}

.custom_search {

  background-color: #fff;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, .12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, .05);
  border-radius: 2px;
}

.modal_serchbox {
  visibility: visible !important;
  opacity: 1 !important;
  margin-bottom: 12px;

  @media screen and (max-width: 991px) {
    background-color: unset;
    position: unset !important;
    padding-left: 0;
    padding-right: 0;

  }
}


@media (max-width: 991.98px) {
  .ant-layout-has-sider {
    >.ant-layout {
      padding: 0 20px;
      max-width: unset !important;
      min-height: 100vh;
      box-sizing: border-box;
      overflow: hidden;

      // .mobileview & {
      //   padding: 0 0;
      // }
    }
  }

  .breadcrumb_heading {
    padding: 12px;
  }
}

.timepicker_align {
  margin-top: 28px;
  margin-left: 25px;
}


.contact_data {
  margin: 22px 0;
  position: relative;
  left: 34%;
}

.record {
  padding-left: 1.5rem;
}


.email_wrap {
  display: flex;
  align-items: center;
}


.depart_flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}


.sub_text {
  margin: 0;
  font-size: 12px;
  // line-height: 20px;
  color: #8C8C8C;
  font-weight: 400;
  margin-bottom: 4px;
}

.main_txt {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  // line-height: 22px;
  color: #000000;
  font-weight: 500;
  opacity: 0.8;
}

.itenary_table {
  tr {
    background-color: unset;
    height: 50px;
    border-bottom: unset !important;

    td {
      border: unset;
    }
  }

  tr:last-child {
    .day_round p::after {
      opacity: 0;
    }
  }

  td:first-child {
    background-color: unset;
  }
}

.settings_details {
  overflow: hidden;
}

.button_flex {
  display: flex;
  gap: 12px;
}

.day_round {
  width: 5rem;
  height: 2rem;
  position: relative;
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
  z-index: 999;

  p {
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 20px;
      background-color: #D9D9D9;
      bottom: -24px;
      left: 50%;
      z-index: 999;
    }
  }
}

.interior_table {
  // padding: 0 24px;

  .interior_heading {
    padding-top: 9px;
    padding-bottom: 9px;
    margin-top: 24px;
    background-color: #fafafa;
  }
}


.sidebar-logo {
  +.ant-menu {
    max-height: 100vh;
    overflow: scroll;
    padding-bottom: 116px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;