.organizations_view{
    
  ol{
    li:nth-child(3){
        .ant-breadcrumb-separator{
            display: none;
        }
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;