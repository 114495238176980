.button_background{
    background-color: #1A7F93;
    color: #fff;

    &:hover{
        background-color: #1A7F93;
        color: #fff;
    }
}

.text_colors {
    color: #1A7F93;
} 
@primary-color: #1A7F93;@processing-color: #1A7F93;