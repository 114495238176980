.bg_main {
  height: 100vh !important;
  width: 100%;
  background-image: url(../../../public/bg-image.jpg);
  opacity: 1;
  background-color: #021529;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 9;


  .bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(2, 29, 49, 0.5);
    z-index: 2;
  }

  .main_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0px;

    .main_login-container {
      background-color: #fff;

      padding: 48px;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      max-width: 403px;
      min-height: 440px;
      position: relative;
      z-index: 16;

      .main_login-container--logo {
        padding-bottom: 48px;
        text-align: center;
      }

      .login_remember {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .login_bottom {
    position: absolute;
    bottom: 0;
    z-index: 90;

    p {
      color: #fff;
      font-weight: 400;
    }
  }
}

.Set_password-main {
  .login_bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 475px) {
  .bg_main .main_login .main_login-container {
    width: 345px;
  }
}

.ckeitor-field-wrapper {
  .ant-form-item-control {
    max-width: 100%;
  }
}
.edit-traveller-wrapper {
  .ict-custom-accordion--content {
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
  }
}

.edit-traveller-wrapper {
  .accordion-form-wrapper {
    .ict-custom-accordion--content {
      opacity: 1;
      visibility: visible;
      height: 100%;
      padding: 1rem;
    }
  }
}

@primary-color: #1A7F93;@processing-color: #1A7F93;