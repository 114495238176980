.breadcrumb_content {
  width: 100%;

  .view_header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .breadcrumb_group {
      display: flex;
      gap: 14px;

      .breadcrumb_title {
        display: flex;
        gap: 12px;

        @media only screen and (max-width: 1023px) {
          flex-direction: column-reverse;
          gap: 0;
          margin-top: -5px;
        }

        p {
          font-size: 20px;
          font-weight: 600;
          font-family: Poppins, sans-serif;
          color: rgba(0, 0, 0, 0.85);

          margin: 0;


        }

        p:nth-child(2) {
          color: #00000073;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          opacity: 0.7;

          span {
            font-size: 12px;
            cursor: pointer;
            line-height: 20px;
            font-weight: 500;
            font-family: Poppins, sans-serif;
            color: #0099e5;
            border-radius: 2px;
            border: 1px solid #4dc4fe;
            background-color: rgba(230, 247, 255, 0.7);
            padding: 1px 8px;


          }
        }
      }
    }

    .breadcrumb_buttons {
      display: flex;
      gap: 10px;

      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }

    .breadcrumb_toggle {
      display: flex;
      gap: 10px;
      margin-left: auto;

      @media only screen and (min-width: 1025px) {
        display: none;
      }


    }
  }
}

.span_serial {
  color: #1A7F93;
  font-size: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  margin-left: 14px;
}

@media screen and (max-width: 576px) {
  .mobile_padding {
    padding-left: 68px;
  }
}



@primaryColor: #1A7F93;

.traveler_tag {
  font-size: 12px;
  cursor: pointer;
  line-height: 20px;
  font-weight: 500;
  color: #0099e5;
  opacity: 0.6;
  font-family: 'Poppins';
  border: 1px solid #0099e5;
  border-radius: 2px;
  background-color: #0099e5;
  padding: 1px 8px;
  margin-left: 12px;
}

.breadcrumb_buttons {
  display: flex;
  gap: 1rem;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;