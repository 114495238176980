.overflow-scroll {
  padding-bottom: 1rem;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 10px;

    &-track {
      background-color: #bbb;
    }

    &-thumb {
      background-color: #1a7f93;
    }

    &-track,
    &-thumb {
      border-radius: 12px;
    }
  }

}
@primary-color: #1A7F93;@processing-color: #1A7F93;