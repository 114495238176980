.Res_details{
    margin-bottom: 24px;
}
.ict-custom-accordion {
    &--header {
        display: flex;
        align-items: center;
        background-color: #fafafa;
        th {
            background-color: #fff;
        }

    }
    &--content {
        padding: 16px;
    }
   
}

.down_color{
    color: #000;
}

.date_time {
    display: flex;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;