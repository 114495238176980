.responsive{
.hide {
    display: none;
}


.ant-card-bordered{
    border: none !important;
    border-top: 1px solid #f0f0f0 !important;
}
}
@primary-color: #1A7F93;@processing-color: #1A7F93;