.partnership_inform {
  align-items: baseline;
}

.event_unassign_search {
  position: relative;

  .ant-input-search {
    position: absolute;
    top: 15px;
    width: 40%;
    z-index: 9;
  }
}

.reservation_main-tablee {
  .ant-table-row-level-0 {
    background: #f5f5f5 !important;
  }

  .ant-layout-content {
    padding: 16px;
    height: 100%;
  }

  .ant-table-tbody {
    tr {
      border-bottom: 1px solid #eee;
      height: 0% !important;

      &:hover {
        td {
          background-color: transparent;
        }
      }

      td {
        align-items: center;
      }
    }

    .ant-table-row-level-0 {
      >td.ant-table-selection-column {
        opacity: 0;
      }
    }

    .ant-table-row-level-1 {
      >.ant-table-cell-with-append {

        .ant-table-row-expand-icon-spaced,
        .ant-table-row-indent {
          display: none !important;
        }
      }
    }
  }

  .ant-table-row-indent+.ant-table-row-expand-icon {
    margin-top: -4px;
  }
}

// .reservation_main-table {
//   .ant-table-pagination:nth-child(1) {
//     margin-top: 3rem;
//   }
// }

.centerassigned-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.motor_right-section {
  >.ant-layout-content {
    height: 100%;
    padding: 12px;

    .reservation_main-tablee {
      .ant-table-thead {
        >tr {
          >th {
            &:nth-child(1):after {
              position: absolute;
              top: 50%;
              right: 0;
              width: 1px;
              height: 1.6em;
              background-color: rgba(0, 0, 0, 0.06) !important;
              transform: translateY(-50%);
              transition: background-color 0.3s;
              content: "";
            }
          }
        }
      }

      .ant-table-tbody {
        >tr &:hover {
          background-color: transparent !important;
        }

        >tr.ant-table-row-level-1 {
          >td {
            &:nth-child(1) {
              background-color: #fafafa;
            }

            &:nth-child(3) {
              background-color: #fafafa;
            }

            &:nth-child(5) {
              background-color: #fafafa;
            }

            &:nth-child(7) {
              background-color: #fafafa;
            }

            &:nth-child(9) {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}

.motor_coach-section {
  .ant-layout-content {
    padding: 12px;

    .reservation_main-tablee {
      .ant-table-thead {
        >tr {
          >th {
            padding: 16px 10px;

            &:nth-child(1):after {
              position: absolute;
              top: 50%;
              right: 0;
              width: 1px;
              height: 1.6em;
              background-color: rgba(0, 0, 0, 0.06) !important;
              transform: translateY(-50%);
              transition: background-color 0.3s;
              content: "";
            }
          }
        }
      }

      .ant-table-tbody {
        >tr.ant-table-row-level-1 {
          >td {
            &:nth-child(1) {
              background-color: #fafafa;
            }

            &:nth-child(3) {
              background-color: #fafafa;
            }

            &:nth-child(5) {
              background-color: #fafafa;
            }

            &:nth-child(7) {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}


.assign-table {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    &-track {
      background-color: #bbb;
    }

    &-thumb {
      background-color: #1a7f93;
    }

    &-track,
    &-thumb {
      border-radius: 12px;
    }
  }
}


.assign-table {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    &-track {
      background-color: #bbb;
    }

    &-thumb {
      background-color: #1a7f93;
    }

    &-track,
    &-thumb {
      border-radius: 12px;
    }
  }
}

// .details-tabs {
//   .ant-tabs-nav {
//     .ant-tabs-nav-wrap {
//       .ant-tabs-nav-list {
//         .ant-tabs-tab {
//           &:nth-last-child(2) {
//             padding-right: 4rem;
//           }
//         }
//       }
//     }
//   }
// }
@primary-color: #1A7F93;@processing-color: #1A7F93;