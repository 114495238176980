.events_reservation {
  .ant-card-bordered:last-child {
    @media screen and (max-width: 1023px) {
      margin-top: 20px;
    }
  }

  .ant-card-body {
    padding-bottom: 1rem !important;
  }
}

.card_paragraph {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  gap: 8px;

  .card_span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.card_bottom {
  margin-bottom: 11px;

  .ant-card-body {
    padding: 6px 15px;
  }
}

.card_icons {
  display: flex;
  justify-content: space-between;
}


.manage_market_sources .-tree-treenode {
  position: relative;
}

.manage_market_sources .ant-tree-node-content-wrapper {
  position: unset !important;
}

.manage_market_sources {
  .marketing_action_btn {
    display: flex;
    gap: 1rem;
    position: absolute;
    right: 1rem;
  }


}

.anticon-delete:hover {
  color: red !important;
}

.manage_market_sources .marketing_tree .ant-tree-treenode-leaf-last:first-child {
  padding-left: 23px;
}

.textarea_group {
  textarea {
    width: 100%;
    padding: 1rem;
  }
}

.updated_check {
  margin-bottom: 29px !important;
}

.updated_check+h2 {
  margin: 0;
}

.marketing_tree .ant-tree-treenode-switcher-close {
  position: relative;
  top: 12px;
  padding-left: 11px;
  margin-left: -8px;

}

.marketing_tree .ant-tree-switcher_open {
  margin-top: 3px;
}

.marketing_tree .ant-tree-switcher_close {
  margin-top: 3px;
}

.manage_market_sources .marketing_tree .ant-tree-treenode-leaf-last:first-child {
  padding-left: 31px;
}

.marketing_tree .ant-tree-treenode-switcher-open {
  padding-left: 6px;
}

.events_reservation {
  max-height: 550px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    &-track {
      background-color: #bbb;
    }

    &-thumb {
      background-color: #1a7f93;
    }

    &-track,
    &-thumb {
      border-radius: 12px;
    }
  }
}

.export-dropdown {
  border: 1px solid #d9d9d9;
  padding: 3px 22px;
  color: #333;
}

.select_grid_field {
  margin-top: -3.5rem;
  margin-left: 10rem;

  .ant-form-item-row {
    justify-content: center;
  }
}

@media (min-width: 1400px) and (max-width: 1499.98px) {
  .select_grid_field {
    margin-left: 12rem;
    width: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .select_grid_field {
    margin-left: 9.5rem;
    width: 100%;
  }
}

.dining-tag {
  margin-bottom: 8px;
}


.refund-via-wrapper {
  display: flex;
  gap: 10px;


   .dropdown-refund {
     width: 450px;
   }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;