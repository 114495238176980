.tabs_style {
  padding: 16px 24px;
  h4 {
    margin-left: 7px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-table-wrapper {
    .ant-spin-nested-loading {
      .ant-table-tbody {
        tr {
          td:first-child {
            // display: inline-block;
            width: 500px;
            height: 46px !important;
            padding-top: 11px !important;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
         
          }

          td:last-child {
            // display: inline-block;
            padding-top: 11px !important;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
          }

       
        }

    
      }
    }
  }

  .Address_card {
   

    .ant-card-body {
      overflow: auto;
      width: 100%;
    }

    .ant-anchor-fixed {
      position: unset;
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;