.messageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #146170;
    font-family: "Poppins";
    height: 100vh;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .messageBody {
    background: #ecf3f5;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 112px;
    border-radius: 6px;
    /* justify-content: space-between; */
  }

  .message {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.154px;
    color: #242d60;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }