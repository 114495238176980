.span_text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
}

.close-btn {
  display: flex;
  justify-content: end;
  margin-top: -1rem;
}

.flights-anticon-icons {
  display: flex;
  gap: 1rem;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;