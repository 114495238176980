.ant-steps-vertical {
    // position: absolute;

    .ant-steps-item-content:nth-child(3) {
        line-height: 22px !important;
    }
}

.steps-action {
    display: flex;
    justify-content: center;
    margin: auto;
}

.steps-action {
    flex-direction: row-reverse;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail::after {
    width: 1px;
    height: 100%;
}

.border_container {
    border-left: 2px solid whitesmoke;
}


@media only screen and (max-width: 991px) {
    .ant-layout-sider {
        display: none;
    }

    .border_container {
        border-left: none;
    }

    .ant-steps {
        padding: 24px 0px;
    }


}

.upload-photo {
    .ant-form-item-label {
        display: flex;
        justify-content: end;
        align-items: center;
    }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;