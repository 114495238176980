// Global Type Two Heading which are using in Tree Structure Forms

.form-heading-two {
  margin-bottom: 20px; // Space Between Heading and Form
  background-color: #FAFAFA; // Background color of Heading
  text-transform: capitalize;
  padding: 4px;
}

// Refund Modal Styling

.refund-modal {
  p {
    font-size: 14px;
    line-height: 22px;
    font-family: "Poppins";
    font-weight: 400;

    span {
      font-weight: 700;
    }
  }
}

// Void Modal Styling

.void-modal {
  p {
    font-size: 14px;
    line-height: 22px;
    font-family: "Poppins";
    font-weight: 700;
    color: #000000D9;
    opacity: 0.85;
  }

  span {
    font-size: 14px;
    line-height: 22px;
    font-family: "Poppins";
    font-weight: 400;
    color: #F5222D;
  }

  .void-modal_form {
    margin-top: 2rem;

    label {
      font-size: 14px;
      line-height: 22px;
      font-family: "Poppins";
      font-weight: 700;
      color: #000000D9;
      opacity: 0.85;
    }

    textarea {
      font-size: 14px;
      line-height: 22px;
      font-family: "Poppins";
      font-weight: 700;
      color: #000000D9;
      opacity: 0.85;
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;