.Event_assignbtn_main {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  button {
    margin-bottom: 8px;
  }
}

/* Default max-height */
.rightunussigned-section,
.leftunussigned-section {
  max-height: 530px !important;

  .ant-layout-content {
    max-height: 530px !important;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;

      &-thumb {
        background-color: #1a7f93;
      }

      &-track,
      &-thumb {
        border-radius: 12px;
      }
    }
  }
}

/* Above 1600px screen size */
@media (min-width: 1601px) {
  .rightunussigned-section,
  .leftunussigned-section {
    max-height: 80vh !important;

    .ant-layout-content {
      max-height: 80vh !important;
    }
  }
}

/* Between 1400px and 1600px screen size */
@media (min-width: 1400px) and (max-width: 1600px) {
  .rightunussigned-section,
  .leftunussigned-section {
    max-height: 78vh !important;

    .ant-layout-content {
      max-height: 78vh !important;
    }
  }
}

/* Between 1200px and 1400px screen size */
@media (min-width: 1200px) and (max-width: 1400px) {
  .rightunussigned-section,
  .leftunussigned-section {
    max-height: 75vh !important;

    .ant-layout-content {
      max-height: 75vh !important;
    }
  }
}

@primary-color: #1A7F93;@processing-color: #1A7F93;