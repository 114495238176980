.eventtable_flight{
    padding: 10px 0px;
    p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #8C8C8C;
        &:hover {
            color: #000;
        }

    }
   
    h1{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
    }
}
.tag_btn{
    text-align: end;
}


.custom_para {
    .eventtable_flight {
        p{
            color: #8C8C8C !important;

            &:hover {
                color: #8C8C8C !important;
            }
        }
    }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;