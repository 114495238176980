// global search icon green
.ant-input-search {
  &:hover .anticon svg {
    color: #1a7f93;
  }
}

.partnership_inform {
  align-items: baseline;

  h4 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }
}

.event_unassign_search {
  position: relative;

  .ant-input-search {
    position: absolute;
    top: 15px;
    width: 40%;
    z-index: 9;

    &:hover .anticon svg {
      color: #1a7f93;
    }
  }
}

.reservation_main-tablee {
  .ant-layout-content {
    padding: 16px;
    height: 100%;
  }

  .ant-table-tbody {
    .ant-table-row:hover {
      background: transparent !important;
    }

    tr {
      border-bottom: 1px solid #eee;
      height: 0% !important;

      &:hover {
        td {
          background-color: transparent;
        }
      }

      td {
        align-items: center;
      }
    }

    .ant-table-row-level-0 {
      >td.ant-table-selection-column {
        opacity: 0;
      }

      >td.ant-table-cell-with-append {
        position: absolute;
        left: 0;
        align-items: center;
        display: flex;
      }
    }

    .ant-table-row-level-1 {
      >.ant-table-cell-with-append {

        .ant-table-row-expand-icon-spaced,
        .ant-table-row-indent {
          display: none !important;
        }
      }

      &:hover {
        background-color: #fff !important;
      }
    }
  }

  .ant-table-row-indent+.ant-table-row-expand-icon {
    margin-top: -4px;
  }
}

.centerassigned-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightunussigned-section {
  >.ant-layout-content {
    height: 100%;
    padding: 12px;

    .reservation_main-tablee {
      .ant-table-thead {
        >tr {
          >th {
            &:nth-child(1):after {
              position: absolute;
              top: 50%;
              right: 0;
              width: 1px;
              height: 1.6em;
              background-color: rgba(0, 0, 0, 0.06) !important;
              transform: translateY(-50%);
              transition: background-color 0.3s;
              content: "";
            }
          }
        }
      }

      .ant-table-tbody {
        >tr &:hover {
          background-color: transparent !important;
        }

        >tr.ant-table-row-level-1 {
          >td {
            &:nth-child(1) {
              background-color: #fafafa;
            }

            &:nth-child(3) {
              background-color: #fafafa;
            }

            &:nth-child(5) {
              background-color: #fafafa;
            }

            &:nth-child(7) {
              background-color: #fafafa;
            }

            &:nth-child(9) {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}

.leftunussigned-section {
  .ant-layout-content {
    padding: 12px;

    .reservation_main-tablee {
      .ant-table-thead {
        >tr {
          >th {

            // padding: 16px 10px;
            &:nth-child(1):after {
              position: absolute;
              top: 50%;
              right: 0;
              width: 1px;
              height: 1.6em;
              background-color: rgba(0, 0, 0, 0.06) !important;
              transform: translateY(-50%);
              transition: background-color 0.3s;
              content: "";
            }

            &:nth-child(2) {}
          }
        }
      }

      .ant-table-tbody {
        >tr>td {}

        >tr.ant-table-row-level-1 {
          >td {
            &:nth-child(1) {
              background-color: #fafafa;
            }

            &:nth-child(2) {}

            &:nth-child(3) {
              background-color: #fafafa;
            }

            &:nth-child(4) {}

            &:nth-child(5) {
              background-color: #fafafa;
            }

            &:nth-child(6) {}

            &:nth-child(7) {
              background-color: #fafafa;
            }
          }
        }
      }
    }
  }
}

// arrow

.icon_change_transfer {
  .ant-table-tbody tr td:nth-child(1) {
    font-weight: 400;
  }

  .ant-table-row-expand-icon {
    font-size: 20px;
    background-color: transparent;
    border: none;
    font-weight: 400;
  }

  .ant-table-row-expand-icon::after {
    position: absolute;
    background: none !important;
    transition: transform 0.1s ease-out;
    content: ">";
    display: inline-block;
    position: absolute;
    top: -22%;
    left: 50%;
    margin-left: -0.7em;
    width: 1.4em;
    height: 1.4em;
    line-height: 1.3em;
  }

  .ant-table-row-expand-icon-collapsed::before {
    display: none;
  }

  .ant-table-row-expand-icon::before {
    display: none;
  }
}


.cta_hover_color:hover {
  color: #3793A1;
}

.primary_cta {
  color: #3793A1;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;