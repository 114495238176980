.ant-breadcrumb {
  background-color: #fff;
  padding: 16px 20px;
  margin-bottom: 24px;

  .breadcrumb_Organisation {
    width: 100%;

    .breadcrumb_heading {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      h3 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.85);
        display: flex;
        align-items: center;

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: rgba(0, 0, 0, 0.45);
        }
      }

      .breadcrum_buttons {
        display: flex;
        gap: 10px;
        justify-content: end !important;
        flex-wrap: wrap !important;
      }
    }
  }
}

//
@media screen and (max-width: 767px) {
  .ant-breadcrumb {
    margin: 20px 0px;
    padding: 16px 16px;
  }
}

.position_button {
  position: absolute;
  right: 60px;
  top: 255px;
}

.top {
  top: 260px;
}

.breadcrum_buttons {
  display: flex;
  gap: 12px;
  align-items: center;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;