.pricing_paragraph {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
}

.category_information {
    max-width: 650px;
}

.anchor_weight {
    font-weight: bolder;
}

.anchor_weight_black {
    font-weight: bolder;
    color: rgba(0, 0, 0, 0.85);
}

.collapse_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
}

.span_small {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);

}

.global_divider {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  // height: 100px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  left: 50%;
}

.ant-collapse-expand-icon {
    position: relative;
    top: 6px;
}

.main_overflow {
    overflow: hidden;
}

.pd-16 {
    overflow: hidden;
}
@primary-color: #1A7F93;@processing-color: #1A7F93;