.ant-layout-content{
    padding: 24px;
    background-color: #fff;
    &.transparent-bg {
        padding: 0px;
        background-color: transparent; 
    }
}
@media screen and (max-width: 767px) {
    .ant-layout-content{
        margin: 0;
        padding: 16px;
    } 
}



.organisation_edit{
    
    ol{
      li:nth-child(3){
        font-weight: 500;
        color: #000;
          .ant-breadcrumb-separator{
              display: none;
          }
      }
    }
  }
@primary-color: #1A7F93;@processing-color: #1A7F93;