.partnership_inform {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
        padding-bottom: 0;
        margin: 0;
    }
}


@primary-color: #1A7F93;@processing-color: #1A7F93;