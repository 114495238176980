.alert_box {
  width: 100%;
  margin-top: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 16px;

  .ant-alert-icon {
    // position: absolute;
    // right: -2rem;
  }
}

.revise-btn_modal {
  p {
    color: #000000D9;
    font-size: 14px;
    line-height: 22px;
    font-family: "Poppins";

    span {
      color: #000;
      font-weight: 900;
    }
  }
}
@primary-color: #1A7F93;@processing-color: #1A7F93;